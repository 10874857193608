import React, {Component} from 'react';
import {Message, Loader, Header, Button, Form} from 'semantic-ui-react';
import axios from 'axios';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import {CollapseBox, InputField, intlShape} from '@ecosio/components';
import {Helmet} from 'react-helmet';
import {Field, Form as FinalForm} from 'react-final-form';
import {STATIC_ASSETS_BASE} from '../../constants';
import {email} from '../../helpers/validators';
import {createPageTitle} from '../../helpers/helmetHelpers';
import {
  BoxContainer,
  PasswordResetMessage,
  LinkContainer,
  Img,
  Container,
  FormContainer,
} from './ResetPasswordStyles';

const SuccessMessage = () => (
  <Message positive>
    <Message.Header>
      <Msg id="PW_RESET_SUCCESS_HEADER" />
    </Message.Header>
    <Msg id={'PW_RESET_SUCCESS_BODY'} />
    <LinkContainer>
      <Link to="/login" tabIndex={-1}>
        <Msg id="PW_BACK_TO_LOGIN" />
      </Link>
    </LinkContainer>
  </Message>
);

const ErrorMessage = () => (
  <Message error>
    <Message.Header>
      <Msg id="PW_RESET_ERROR_HEADER" />
    </Message.Header>
    <p>
      <Msg id={'PW_RESET_ERROR_BODY'} />
    </p>
  </Message>
);

class RequestPasswordReset extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      resetSuccess: false,
      resetFailure: false,
    };
  }

  componentDidMount() {
    // get a csrf token
    axios
      .get('/api/csrf')
      .then(() => {
        this.setState({loading: false});
      })
      .catch((error) => {
        console.error(error);
        this.setState({resetFailure: true});
      });
  }

  onSubmit = (values) => {
    this.setState({loading: true});
    return axios
      .post('/api/init-password-reset', values)
      .then(() => {
        this.setState({
          loading: false,
          resetSuccess: true,
          resetFailure: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading: false,
          resetFailure: true,
          resetSuccess: false,
        });
      });
  };

  render() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const alternateTranslations = Boolean(query.loginTransition);

    if (this.state.loading) {
      return <Loader active />;
    }
    let content;
    let headers;

    if (this.state.resetFailure) {
      content = <ErrorMessage />;
    }

    if (!this.state.resetSuccess === true) {
      headers = (
        <>
          <Header>
            <Msg
              id={
                alternateTranslations
                  ? 'PW_RESET_HEADER_COGNITO'
                  : 'PW_RESET_HEADER'
              }
            />
          </Header>
          <PasswordResetMessage>
            <Msg
              id={
                alternateTranslations
                  ? 'PW_RESET_MESSAGE_COGNITO'
                  : 'PW_RESET_MESSAGE'
              }
            />
          </PasswordResetMessage>
        </>
      );
    } else {
      headers = <SuccessMessage />;
    }

    return (
      <>
        <Helmet>
          <title>
            {createPageTitle(this.props.intl, 'PW_RESET_REQUEST_TITLE')}
          </title>
        </Helmet>
        <Container>
          <CollapseBox style={{margin: 0}}>
            <BoxContainer>
              <FormContainer success={this.state.resetSuccess}>
                {headers}
                {!this.state.resetSuccess && (
                  <>
                    <FinalForm
                      onSubmit={this.onSubmit}
                      render={({handleSubmit, pristine}) => (
                        <Form onSubmit={handleSubmit} style={{margin: '0'}}>
                          <Field
                            translateError
                            autoFocus
                            component={InputField}
                            name="email"
                            label={<Msg id="GENERAL_EMAIL" />}
                            validate={email}
                          />
                          <Button
                            type="submit"
                            fluid
                            style={{marginTop: '16px'}}
                            disabled={pristine}>
                            <Msg
                              id={
                                alternateTranslations
                                  ? 'PW_SEND_RECOVERY_MAIL_COGNITO'
                                  : 'PW_SEND_RECOVERY_MAIL'
                              }
                            />
                          </Button>
                        </Form>
                      )}
                    />
                    <LinkContainer>
                      <Link to="/login" tabIndex={-1}>
                        <Msg id="PW_BACK_TO_LOGIN" />
                      </Link>
                    </LinkContainer>
                  </>
                )}
              </FormContainer>

              <Img src={`${STATIC_ASSETS_BASE}/assets/password-reset-2.svg`} />
            </BoxContainer>
          </CollapseBox>
          {content}
        </Container>
      </>
    );
  }
}

export default injectIntl(RequestPasswordReset);

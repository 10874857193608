import React, {useCallback, useState} from 'react';
import {Form as SemanticForm} from 'semantic-ui-react';
import {AutoSave, toast} from '@ecosio/components';
import axios from 'axios';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Authority} from '@ecosio/auth';
import {
  getBackendPermissions,
  getPermissionsFormObject,
  PermissionsCheckboxes,
} from '../../Helper/Permissions';
import {SettingsFormWrapper} from '../SettingsStyles';
import {partnerRelationShape} from './partnerRelationsApi';

export const FormWrapper = styled.div`
  margin: 0px auto 64px auto;
`;

const PartnerRelationPermissionsForm = ({relation}) => {
  const intl = useIntl();
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [permissions, setPermissions] = useState(relation.permissions);

  const userConfig = useSelector((state) => state.config.userConfig);
  const company = userConfig.company;

  const hasAuthority =
    userConfig.authorities.indexOf(Authority.COMPANY_USER_UPDATE) > -1;

  const submitCallback = useCallback(
    async (values, form, complete) => {
      const newPermissions = getBackendPermissions(values);
      const url = `/api/partner-relations/${relation.uuid}/change-authorities`;

      try {
        setPermissionsLoading(true);
        await axios.post(url, {newPermissions});
        setPermissions(newPermissions);
        toast({
          title: intl.formatMessage({
            id: 'PARTNER_RELATION_PERMISSIONS_UPDATED',
          }),
          description: '',
          type: 'success',
          time: 4000,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setPermissionsLoading(false);
        complete();
      }
    },
    [intl, relation.uuid]
  );

  const initialValues = getPermissionsFormObject(permissions);
  const disabled =
    permissionsLoading || company.companyType === 'PARTNER' || !hasAuthority;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submitCallback}
      subscription={{}}
      render={({handleSubmit}) => (
        <SemanticForm
          onSubmit={handleSubmit}
          style={{marginTop: 25}}
          data-spec="relation-perm-form">
          <SettingsFormWrapper>
            <FormWrapper>
              <AutoSave debounce={250} />
              <PermissionsCheckboxes
                disabled={disabled}
                isAdminDisabled={false}
              />
            </FormWrapper>
          </SettingsFormWrapper>
        </SemanticForm>
      )}
    />
  );
};

PartnerRelationPermissionsForm.propTypes = {
  relation: partnerRelationShape.isRequired,
};

export default PartnerRelationPermissionsForm;

import {TextContent} from '@ecosio/components';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Grid} from 'semantic-ui-react';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import {fetchSamlAppConfig} from '../api/samlConfigApi';

const SamlAppConfig = ({companyUuid, providerName}) => {
  const intl = useIntl();
  const [samlAppConfig, setSamlAppConfig] = useState(null);

  useEffect(() => {
    const loadSamlAppConfig = async () => {
      const samlAppConfig = await fetchSamlAppConfig(companyUuid, intl);
      setSamlAppConfig(samlAppConfig);
    };
    loadSamlAppConfig();
  }, [companyUuid, intl]);

  return (
    <>
      <InfoMessageWrapper>
        <FormattedMessage id="SAML_CONFIG_DESCRIPTION"></FormattedMessage>
      </InfoMessageWrapper>
      <Grid>
        <Grid.Row style={{padding: 0}}>
          <Grid.Column>
            <TextContent
              data-spec="entity-id-text-content"
              style={{marginBottom: '15px !important'}}
              label={intl.formatMessage({
                id: 'IDENTIFIER_ENTITY_ID_LABEL',
              })}
              value={
                (samlAppConfig && `${samlAppConfig.entityId}`) || ''
              }></TextContent>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{padding: 0}}>
          <Grid.Column>
            <TextContent
              data-spec="reply-url-text-content"
              label={intl.formatMessage({
                id: 'REPLY_URL',
              })}
              value={
                (samlAppConfig &&
                  samlAppConfig.replyUrl.replace(
                    '{registrationId}',
                    providerName || ''
                  )) ||
                ''
              }></TextContent>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

SamlAppConfig.propTypes = {
  providerName: PropTypes.string.isRequired,
  companyUuid: PropTypes.string.isRequired,
};

export default SamlAppConfig;

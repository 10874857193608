import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import {isInternetExplorer, isInternetExplorerEOL} from '@ecosio/components';
import App from './App';
import './App.css';

if (isInternetExplorer() && !isInternetExplorerEOL()) {
  ReactDOM.render(<div />, document.getElementById('root'));
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

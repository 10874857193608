import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {Button, Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import Label from '../Layout/Label';

import {
  ContentBox,
  ContinueButton,
  Header,
  HeaderImage,
  InfoParagraph,
  Layout,
  Subtitle,
  TextContainer,
} from '../Invite/InviteLayout';
import {samlOnboardingLayout} from './stledComponents';
import useRedirectOnboardedUser from './useRedirectOnboardedUser';

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const SamlSuccessPage = ({location}) => {
  const history = useHistory();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const {name} = query;

  useRedirectOnboardedUser();

  const onFinish = () => {
    // Force a hard reload.
    window.location.href = '/account';
  };

  return (
    <Layout style={samlOnboardingLayout}>
      <ContentBox>
        <HeaderImage url="ecosio_onboarding-success.svg" position="left" />

        <TextContainer>
          <Header>
            <FormattedMessage id="INVITE_SUCCESS_HEADER" />
          </Header>
          {name && <Subtitle data-spec="sub-name">{name}</Subtitle>}
          <InfoParagraph>
            <FormattedMessage id="SUCCESS_SAML_PAGE_PARAGRAPH" />
          </InfoParagraph>
        </TextContainer>
        <Footer>
          <Button
            style={{backgroundColor: 'white'}}
            type="button"
            onClick={() => history.goBack()}
            data-spec="back-button">
            <Label messageId="GENERAL_BACK" style={{margin: 0}}></Label>
          </Button>
          <ContinueButton
            size="160px"
            onClick={onFinish}
            data-spec="finish-saml-onboarding">
            <FormattedMessage id="INVITE_SUCCESS_BUTTON" />
            <Icon name="check" />
          </ContinueButton>
        </Footer>
      </ContentBox>
    </Layout>
  );
};

SamlSuccessPage.propTypes = {
  location: PropTypes.object,
};

export default injectIntl(SamlSuccessPage);

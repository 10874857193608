import React, {Fragment} from 'react';
import axios from 'axios';
import {Button, Grid, Header, Icon} from 'semantic-ui-react';
import {
  CollapseBox,
  InternalError,
  PageNotFound,
  toast,
} from '@ecosio/components';
import styled from 'styled-components';
import {
  FormattedMessage as Msg,
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import {Helmet} from 'react-helmet';
import {Form, Input, InputArray} from '@ecosio/pathform';
import {connect} from 'react-redux';

import {get} from 'lodash';
import {SettingsFormWrapper} from '../SettingsStyles';
import SubmitButton from '../SubmitButton';
import {historyShape} from '../../../../shapes';
import {
  email,
  processValidationResult,
  required,
  requiredEmail,
} from '../../../helpers/validators';
import {FormFieldsWrapper} from '../FormFieldsWrapper';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import {MainCategory, trackEvent} from '../../../analytics';
import CompanyDynamicFields from './CompanyDynamicFields';

const Logo = styled.div`
  height: 81px;
`;

const countryOptions = (countryAbbreviations) =>
  countryAbbreviations.map((status) => ({
    text: status,
    value: status,
    key: status,
  }));

const mapStateToProps = (state) => ({
  userConfig: state.config?.userConfig,
});

@connect(mapStateToProps)
class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {disableButton: false, errorCode: false};
  }

  componentDidMount() {
    this.fetchCompany();
    this.setStaticFormFields();
  }

  async fetchCompany() {
    try {
      const response = await axios.get(`/api/company`);
      // eslint-disable-next-line no-empty
      if (
        response &&
        response.data &&
        (!response.data.ediSupportEmails ||
          response.data.ediSupportEmails.length === 0)
      ) {
        response.data = {...response.data, ediSupportEmails: ['']};
      }

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({companyDetails: response?.data});
    } catch (error) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({errorCode: error.response.status});
      console.error(error);
    }
  }

  handleNetworkError = (error, intl) => {
    if (error.response.status === 409) {
      toast({
        title: intl.formatMessage({id: 'SETTINGS_CONCURRENT_UPDATE_TITLE'}),
        description: intl.formatMessage({
          id: 'SETTINGS_CONCURRENT_UPDATE_DESCRIPTION',
        }),
        type: 'warning',
        time: 100 * 1000,
      });
    } else {
      console.error(error);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: error.message,
        type: 'error',
        time: 100 * 1000,
      });
    }
  };

  handleResult = (res, intl) => {
    if (res.status === 200) {
      toast({
        title: intl.formatMessage({id: 'COMPANY_UPDATE_SUCCESS_TITLE'}),
        description: intl.formatMessage({
          id: 'COMPANY_UPDATE_SUCCESS_DESCRIPTION',
        }),
        type: 'success',
        time: 4000,
      });
      this.fetchCompany();
    }
  };

  trackAnalytics = (event) => {
    trackEvent(event, {
      mainCategory: MainCategory.ButtonClick,
      companyUuid: this.state.userConfig?.company?.uuid,
      companyName: this.state.userConfig?.company?.name,
    });
  };

  onSubmit = async (values) => {
    const {intl} = this.props;
    this.setState({disableButton: true});

    return await axios
      .post(`/api/company`, values)
      .then((res) => {
        this.setState({disableButton: false});
        this.handleResult(res, intl);
        this.trackAnalytics('Save company profile');
      })
      .catch((e) => {
        if (e.response?.status === 400) {
          this.setState({disableButton: false});
          return processValidationResult(e.response?.data, intl);
        } else {
          this.setState({disableButton: false});
          this.handleNetworkError(e, intl);
        }
      });
  };

  setStaticFormFields = () => {
    const {intl} = this.props;
    const countryAbbreviations = Object.keys(intl.messages)
      .filter((k) => k.startsWith('COUNTRY'))
      .map((k) => k.split('_')[1]);
    const staticFormfields = {
      street: {
        path: 'street',
        type: 'text',
        input: {
          label: 'GENERAL_STREET',
          type: 'text',
        },
      },
      pob: {
        path: 'pob',
        type: 'text',
        input: {
          label: 'GENERAL_POST_OFFICE_BOX',
          type: 'text',
        },
      },
      country: {
        path: 'country',
        type: 'dropdown',
        input: {
          label: 'GENERAL_COUNTRY',
          type: 'text',
        },
        options: countryOptions(countryAbbreviations),
      },
      zip: {
        path: 'zip',
        type: 'text',
        input: {
          label: 'GENERAL_POSTAL_CODE',
          type: 'text',
        },
      },
      city: {
        path: 'city',
        type: 'text',
        input: {
          label: 'GENERAL_CITY',
          type: 'text',
        },
      },
      email: {
        path: 'email',
        type: 'text',
        input: {
          label: 'GENERAL_EMAIL',
          type: 'text',
        },
      },
      ediSupportEmails: {
        path: 'ediSupportEmails',
        type: 'text',
        input: {
          label: 'GENERAL_EDI_SUPPORT_EMAIL',
          type: 'text',
        },
      },
      web: {
        path: 'web',
        type: 'text',
        input: {
          label: 'GENERAL_WEBSITE',
          type: 'text',
        },
      },
      uid: {
        path: 'uid',
        type: 'text',
        input: {
          label: 'GENERAL_COMPANY_UID',
          type: 'text',
        },
      },
      fiscalNumber: {
        path: 'fiscalNumber',
        type: 'text',
        input: {
          label: 'GENERAL_FISCAL_NUMBER',
          type: 'text',
        },
      },
    };

    this.setState({formFields: staticFormfields});
  };

  addDynamicFields = (dynamicFormFields) => {
    this.setState((prevState) => ({
      formFields: {
        ...prevState.formFields,
        ...dynamicFormFields,
      },
    }));
  };

  render() {
    const {intl, history, userConfig} = this.props;
    const {disableButton, companyDetails, errorCode} = this.state;
    const initialValues = companyDetails;
    const formEnabled = userConfig?.authorities?.includes('COMPANY_USER_ADD');

    const webEdiConfig = userConfig?.modules.find(
      (module) => module.name === 'WEB_EDI'
    );
    const moduleSpecificTranslations = webEdiConfig?.moduleSpecificTranslations;

    const noticeHeader = get(
      moduleSpecificTranslations,
      'COMPANY_PROFILE_NOTICE_HEADER'
    );

    const noticeDescription = get(
      moduleSpecificTranslations,
      'COMPANY_PROFILE_NOTICE_DESCRIPTION'
    );

    if (errorCode) {
      if (errorCode === 404) {
        return <PageNotFound history={history} />;
      }

      if (errorCode === 500) {
        return <InternalError />;
      }
    }
    return (
      <SettingsFormWrapper>
        <CollapseBox header={<Msg id="SETTINGS_COMPANY_HEADER" />}>
          <Helmet>
            <title>{createPageTitle(intl, 'COMPANY_PAGE_TITLE')}</title>
          </Helmet>
          <Form
            disabled={!formEnabled}
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            fields={this.state.formFields}>
            {() => {
              return (
                <Fragment>
                  <FormFieldsWrapper>
                    <Grid>
                      <Logo>
                        {/*TO-DO: Logo will be implemented later in another ticket
                        <Grid.Row>LOGO Space holder</Grid.Row>*/}
                      </Logo>
                      <div>
                        {noticeHeader ? (
                          <Header as="h3">
                            {intl.formatMessage({id: noticeHeader})}
                          </Header>
                        ) : (
                          ''
                        )}
                        {noticeDescription ? (
                          <p>{intl.formatMessage({id: noticeDescription})}</p>
                        ) : (
                          ''
                        )}
                      </div>
                      <Grid.Row columns={2}>
                        <Grid.Column width={11}>
                          <Input
                            data-spec="company-page-street"
                            path="street"
                            name="street"
                            required
                            validate={required}
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <Input
                            data-spec="company-page-pob"
                            path="pob"
                            name="pob"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={3}>
                        <Grid.Column width={3}>
                          <Input
                            data-spec="company-page-country"
                            path="country"
                            name="country"
                            compact
                            search
                            selectOnBlur={false}
                            required
                            upward={false}
                            validate={required}
                            format={(value) => value}
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <Input
                            data-spec="company-page-zip"
                            path="zip"
                            name="zip"
                            required
                            validate={required}
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Input
                            data-spec="company-page-city"
                            path="city"
                            name="city"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Input
                            translateError
                            data-spec="company-page-email"
                            path="email"
                            name="email"
                            validate={email}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <InputArray name="ediSupportEmails">
                            {({fields}) => (
                              <div style={{flex: '1'}}>
                                {fields.map((name, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      'align-items': 'center',
                                      gap: '5px',
                                    }}>
                                    <div style={{flex: '1'}}>
                                      <Input
                                        translateError
                                        data-spec="company-page-ediSupportEmail"
                                        path="ediSupportEmails"
                                        name={name}
                                        validate={requiredEmail}
                                        required
                                      />
                                    </div>
                                    <Button
                                      style={{
                                        maxHeight: '38px',
                                        'margin-top': '23px',
                                      }}
                                      disabled={index === 0}
                                      icon
                                      negative
                                      type="button"
                                      onClick={() => fields.remove(index)}>
                                      <Icon name="delete" />
                                    </Button>
                                  </div>
                                ))}
                                <Button
                                  onClick={() => fields.push('')}
                                  style={{'margin-top': '10px'}}
                                  positive>
                                  <Icon name="add" />
                                  &nbsp;
                                  <FormattedMessage
                                    defaultMessage={'add edi support email'}
                                    id="GENERAL_ADD_EDI_SUPPORT_EMAIL"
                                  />
                                </Button>
                                <br />
                              </div>
                            )}
                          </InputArray>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Input
                            data-spec="company-page-web"
                            path="web"
                            name="web"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Input
                            data-spec="company-page-uid"
                            name="uid"
                            path="uid"
                            required
                            validate={required}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Input
                            data-spec="company-page-fiscalNumber"
                            name="fiscalNumber"
                            path="fiscalNumber"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <CompanyDynamicFields
                      formEnabled={formEnabled}
                      updateFormFields={this.addDynamicFields}
                    />
                  </FormFieldsWrapper>
                  <SubmitButton
                    dataSpec="company-page-submitButton"
                    disabled={disableButton}
                    textId="SAVE_CHANGES"
                  />
                </Fragment>
              );
            }}
          </Form>
        </CollapseBox>
      </SettingsFormWrapper>
    );
  }
}

CompanyForm.propTypes = {
  //  intl: intlShape.isRequired,
  history: historyShape.isRequired,
};
export default injectIntl(CompanyForm);

import {DataGrid} from '@ecosio/components';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {PermissionsList} from '../../Helper/Permissions';
import {Avatar} from '../Team/Helpers';
import {LastLogin} from '../Team/TeamPage/dataGridConfig';
import PartnerRelationPermissionsForm from './PartnerRelationPermissionsForm';
import ActionCell from './PartnerUserActionCell';
import {partnerRelationShape} from './partnerRelationsApi';

export const cellWidths = [1, 6, 2, 2, 2, 2, 1];

const PartnerRelationUserTable = ({relation, onUserDelete, isPartner}) => {
  const intl = useIntl();
  const preparedConsultants = relation.consultants.map((consultant) => {
    return Object.assign(consultant, {relationUuid: relation.uuid});
  });

  const dataGridConfig = {
    idSelector: 'userId',
    fields: [
      {
        id: 'avatar',
        label: ' ',
        hidden: false,
        translated: false,
        render: (Table, value, row) => (
          <Table.Cell width={cellWidths[0]} textAlign="center">
            {row?.user?.firstName && row?.user?.lastName && (
              <Avatar
                firstName={row.user.firstName}
                lastName={row.user.lastName}
                email={row.userId}
              />
            )}
          </Table.Cell>
        ),
      },
      {
        id: 'fullName',
        label: intl.formatMessage({id: 'GENERAL_NAME'}),
        hidden: false,
        render: (Table, value, row) => (
          <Table.Cell width={cellWidths[1]}>
            <div>
              {row?.user?.firstName && row?.user?.lastName && (
                <div>
                  <span>{row.user.firstName}</span>{' '}
                  <span>{row.user.lastName}</span>
                </div>
              )}
              <div>{row.userId}</div>
            </div>
          </Table.Cell>
        ),
      },
      {
        id: 'authorities',
        label: intl.formatMessage({id: 'USER_PERMISSIONS'}),
        hidden: false,
        render: (Table, value, row) => {
          return (
            <Table.Cell width={cellWidths[1]}>
              <PermissionsList permissions={row?.permissions} />
            </Table.Cell>
          );
        },
      },
      {
        id: 'lastLogin',
        label: intl.formatMessage({id: 'LAST_LOGIN_TIME'}),
        hidden: false,
        render: (Table, value, row) => (
          <Table.Cell width={cellWidths[1]}>
            <LastLogin timestamp={row?.user?.lastLogin} />
          </Table.Cell>
        ),
      },
      {
        id: 'actions',
        label: ' ',
        hidden: false,
        translated: false,
        render: (Table, value, row) => {
          return (
            <Table.Cell width={cellWidths[1]} style={{textAlign: 'right'}}>
              <ActionCell
                userPermissions={row.permissions}
                relationUuid={row.relationUuid}
                userId={row.userId}
                isPartner={isPartner}
                onUserDelete={onUserDelete}
              />
            </Table.Cell>
          );
        },
      },
    ],
  };

  return (
    <div style={{marginBottom: '30px'}} data-spec={`relation-${relation.uuid}`}>
      {isPartner ? (
        <h3 data-spec="company-name">
          <FormattedMessage id={'GENERAL_COMPANY'} /> -{' '}
          {relation.standardCompanyName}
        </h3>
      ) : (
        <h3 data-spec={`partner-name-${relation.uuid}`}>
          Partner - {relation.partnerCompanyName}
        </h3>
      )}
      <div data-spec={`relation-permissions-${relation.uuid}`}>
        <PartnerRelationPermissionsForm relation={relation} />
      </div>
      <DataGrid
        celled={false}
        translated={false}
        data-spec={`partner-consultants-${relation.uuid}`}
        emptyState={{
          icon: 'user',
          header: 'NO_PARTNERS_USERS_FOUND',
          subHeader: 'NO_PARTNERS_USERS_FOUND_SUB',
        }}
        data={preparedConsultants}
        config={dataGridConfig}
      />
    </div>
  );
};

PartnerRelationUserTable.propTypes = {
  relation: partnerRelationShape.isRequired,
  onUserDelete: PropTypes.func.isRequired,
  isPartner: PropTypes.bool.isRequired,
};

export default PartnerRelationUserTable;

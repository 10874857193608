import PropTypes from 'prop-types';

import {DropdownField, InputField, intlShape} from '@ecosio/components';
import React from 'react';
import {Field} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Header, Icon, Loader} from 'semantic-ui-react';
import styled from 'styled-components';
import {required} from '../../../../helpers/validators';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import useSamlAllowedCompanies from '../hooks/useSamlAllowedCompanies';

const StyledIcon = styled(Icon)`
  color: ${(props) => {
    return props.theme.primaryColor;
  }};
  cursor: pointer;
`;

const CompanyMappingWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .field ~ .field {
    margin-left: 1.5rem;
  }
  .field ~ i {
    margin-left: 1rem;
  }
`;

const CompanyMapping = ({
  intl,
  companies,
  index,
  name,
  mappingMode,
  canEditAttributeValue,
  canRemove,
  onMappingRemoved,
}) => {
  const mappingByDomainIdentifier = mappingMode === DOMAIN_IDENTIFIER.value;
  return (
    <CompanyMappingWrapper data-spec={`company-mapping-${index}`}>
      <Field
        label={intl.formatMessage({
          id: 'GENERAL_COMPANY',
        })}
        helpText={<FormattedMessage id="SAML_COMPANY_MAPPING_HELP" />}
        name={`${name}.companyUuid`}
        data-spec={`mapped-company-dd-${index}`}
        component={DropdownField}
        options={companies.map((company) => ({
          value: company.uuid,
          text: company.name,
        }))}
        required
        width={8}
      />
      <Field
        component={InputField}
        name={`${name}.attributeValue`}
        label={intl.formatMessage({
          id: mappingByDomainIdentifier
            ? DOMAIN_IDENTIFIER.trKey
            : 'SAML_MAPPING_ATTRIBUTE_VALUE',
        })}
        helpText={
          <FormattedMessage
            id={
              mappingByDomainIdentifier
                ? 'SAML_MAPPING_DOMAIN_IDENTIFIER_HELP'
                : 'SAML_MAPPING_ATTRIBUTE_VALUE_HELP'
            }
          />
        }
        required
        placeholder={intl.formatMessage({
          id: mappingByDomainIdentifier
            ? DOMAIN_IDENTIFIER.trKey
            : 'SAML_MAPPING_ATTRIBUTE_VALUE',
        })}
        width={canRemove ? 7 : 8}
        translateError
        validate={required}
        disabled={!canEditAttributeValue}
        data-spec={`mapped-attr-${index}`}></Field>
      {canRemove && (
        <StyledIcon
          name="trash"
          style={{alignSelf: 'center'}}
          onClick={() => onMappingRemoved(index)}></StyledIcon>
      )}
    </CompanyMappingWrapper>
  );
};

CompanyMapping.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  index: PropTypes.number,
  mappingMode: PropTypes.shape({
    value: PropTypes.string,
    trKey: PropTypes.string,
  }),
  canRemove: PropTypes.bool,
  onMappingRemoved: PropTypes.func,
  canEditAttributeValue: PropTypes.bool,
};

export const DOMAIN_IDENTIFIER = {
  value: 'DOMAIN_IDENTIFIER',
  trKey: 'DOMAIN_IDENTIFIER',
};

export const CUSTOM_ATTRIBUTE = {
  value: 'CUSTOM_ATTRIBUTE',
  trKey: 'CUSTOM_SAML_ATTRIBUTE',
};
export const MAPPING_MODES = [DOMAIN_IDENTIFIER, CUSTOM_ATTRIBUTE];

const MappingModeRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .field:first-of-type {
    margin-right: 1.5rem;
  }

  .field:only-of-type {
    margin-right: 0.75rem !important;
  }
`;

const SamlCompanyMappingConfig = ({rootCompanyUuid, mappingMode}) => {
  const intl = useIntl();
  const {isLoading, data: companies} = useSamlAllowedCompanies(rootCompanyUuid);

  if (isLoading) {
    return <Loader></Loader>;
  }

  const mappingByDomainIdentifier = mappingMode === DOMAIN_IDENTIFIER.value;
  const mappingByCustomAttribute = mappingMode === CUSTOM_ATTRIBUTE.value;

  return (
    <>
      <Header>
        <FormattedMessage id="COMPANY_MAPPING_TITLE"></FormattedMessage>
      </Header>
      <InfoMessageWrapper>
        <FormattedMessage id="COMPANY_MAPPING_INFO"></FormattedMessage>
      </InfoMessageWrapper>
      <MappingModeRow
        style={{width: mappingByDomainIdentifier ? '50%' : 'initial'}}>
        <Field
          label={intl.formatMessage({
            id: 'MAPPING_MODE',
          })}
          helpText={<FormattedMessage id="MAPPING_MODE_HELP" />}
          name="companyMappingConfig.mappingMode"
          data-spec="mapping-mode-dd"
          component={DropdownField}
          options={MAPPING_MODES.map((mode) => {
            return {
              value: mode.value,
              text: intl.formatMessage({id: mode.trKey}),
            };
          })}
          required
          validate={required}
          width={mappingByDomainIdentifier ? 16 : 8}
        />
        {mappingByCustomAttribute && (
          <Field
            component={InputField}
            name="companyMappingConfig.mappingAttributeName"
            label={intl.formatMessage({
              id: 'SAML_MAPPING_ATTRIBUTE_NAME',
            })}
            helpText={
              <FormattedMessage id="SAML_MAPPING_ATTRIBUTE_NAME_HELP" />
            }
            placeholder={intl.formatMessage({id: 'SAML_ATTRIBUTE'})}
            width={8}
            translateError
            required
            validate={required}
            data-spec="company-mapping-attr-name"></Field>
        )}
      </MappingModeRow>
      <FieldArray name="companyMappingConfig.samlCompanyMappings">
        {({fields}) => {
          return (
            <>
              {fields.map((name, index) => (
                <CompanyMapping
                  key={index}
                  index={index}
                  name={name}
                  intl={intl}
                  mappingMode={mappingMode}
                  companies={companies}
                  canRemove={mappingByCustomAttribute && index > 0}
                  canEditAttributeValue={mappingByCustomAttribute}
                  onMappingRemoved={(idx) =>
                    fields.remove(idx)
                  }></CompanyMapping>
              ))}
              {mappingByCustomAttribute && (
                <Button
                  onClick={() =>
                    fields.push({
                      companyUuid: rootCompanyUuid,
                      attributeValue: '',
                    })
                  }
                  type="button">
                  <Icon name="plus"></Icon>
                  <FormattedMessage id="ADD_MAPPING_BUTTON"></FormattedMessage>
                </Button>
              )}
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

SamlCompanyMappingConfig.propTypes = {
  mappingMode: PropTypes.string.isRequired,
  rootCompanyUuid: PropTypes.string.isRequired,
};

export default SamlCompanyMappingConfig;

import {ecosioColors} from '@ecosio/customer-layout';
import {Button} from 'semantic-ui-react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

// using styled(Button) did not work for some reason
export const buttonStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

export const samlButtonStyle = Object.assign({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  foo: 'bar',
  color: `blue !important`,
  textTransform: 'uppercase',
});

export const LoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
`;

export const WelcomeWrapper = styled.div`
  display: 'flex';
  flexdirection: 'column';
  height: '100vh';
`;

export const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100vh;
`;

export const Header = styled.h1`
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
`;

export const SubHeader = styled.div`
  max-width: 400px;
  font-size: 14px;
  line-height: 22px;
  color: black;
  margin-bottom: 2rem;
`;

export const HeaderWrapper = styled.div`
  color: #002268;
  max-width: 620px;
`;

export const WelcomeActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const LogoLinkWrapper = styled.div`
  margin-top: 6rem;
`;

export const Logo = styled.img`
  width: 110px;
  height: 21px;
`;

export const SamlLogo = styled.img`
  height: 30px;
`;

export const Illustration = styled.img`
  height: 100vh;

  @media screen and (max-width: 1280px) {
    object-fit: cover;
    object-position: -35% 0;
    width: 50%;
  }
`;

export const NotificationBar = styled.div`
  background-color: ${ecosioColors.primaryBlue};
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: white;
  font-size: 16px;
  padding: 0.75rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfirmButton = styled(Button)`
  float: right;
  margin: 0 -25px 0 0 !important;
  width: 220px;
  display: flex !important;
  justify-content: space-between;
`;

export const SecondaryLoginLink = styled(Link)`
  font-size: 11px;
  color: #a6a6a6;
`;

export const SecondaryLoginExternalLink = styled.a`
  font-size: 11px;
  color: #a6a6a6;
`;

export const NeedHelp = styled.div`
  display: block;
  margin-top: 25px;
  color: ${ecosioColors.ecosioBlues[0]};
`;

import {set} from 'lodash';
import {dynID} from './utils';

/**
 * TODO: should all be moved to a common form-validation-library0
 */

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validates presence of a value.
 */
export const required = (value) => {
  if (Array.isArray(value)) {
    return value.length ? undefined : 'GENERAL_REQUIRED_FIELD';
  }
  return value || value === 0 ? undefined : 'GENERAL_REQUIRED_FIELD';
};

/**
 * Validates an email address.
 */
export const email = (address) => {
  if (!address) {
    return;
  }

  return EMAIL_PATTERN.test(String(address).toLowerCase())
    ? undefined
    : 'VALIDATOR_EMAIL';
};

/**
 * Generates a composed validator out of multiple validators.
 * @param validators
 * @return {function(*=): *}
 */
export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

/**
 * Processes server side result for React FinalForm submit validation.
 * Translates the fieldMessage with intl.
 *
 * Works for nested objects too.
 * E.g:
 * {"emailNotificationConfiguration.to": "Required"}
 * to
 * {emailNotificationConfiguration: {to: "Required"}}
 *
 * Copied from elena.
 * See https://gitlab.ecosio.com/code/el-apps/elena/-/blob/beta/admin3-parent/admin3-frontend/src/components/common/forms/errorHelpers.js?ref_type=heads#L57

 *
 * @param validationDataObject should be an object, for instance: {"fieldName":"fieldMessage", "status":400}
 * @param intl from react-intl
 * @return {{}}
 */
export const processValidationResult = (validationDataObject, intl) => {
  return Object.entries(validationDataObject)
    .filter(([path]) => path !== 'status')
    .reduce((errors, [path, message]) => {
      set(errors, path, intl.formatMessage({id: dynID(message)}));
      return errors;
    }, {});
};

export const translateValidationMessage = (input, intl) => {
  if (typeof input === 'string') {
    // Probably a JSON object, which we have special handling for
    if (input.startsWith('{')) {
      try {
        // semgrep bug here, we don't do xpath evaluation...
        const parsed = JSON.parse(input); // nosemgrep nodejs_scan.javascript-xml-rule-node_xpath_injection
        return intl.formatMessage({id: parsed.translationKey}, parsed.values);
      } catch (error) {
        console.warn(
          `failed to parse translation message ${input} as JSON:`,
          input
        );
        return undefined;
      }
    } else {
      return intl.formatMessage({id: input});
    }
  } else {
    return undefined;
  }
};

export const translateValidationResult = (result, intl) => {
  const translated = Object.entries(result).map(([key, value]) => [
    key,
    translateValidationMessage(value, intl),
  ]);

  return Object.fromEntries(translated);
};

export const requiredEmail = composeValidators(required, email);

export const processElenaValidationResult = (err) =>
  Object.entries(err).reduce((errors, [path, message]) => {
    set(errors, path, message);
    return errors;
  }, {});

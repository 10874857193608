import {useQuery} from '@tanstack/react-query';
import {fetchSamlIdps} from '../api/samlConfigApi';

const useSamlIdps = (companyUuid) => {
  return useQuery(
    ['samlIdps'],
    async () => {
      return await fetchSamlIdps(companyUuid);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useSamlIdps;

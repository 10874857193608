import PropTypes from 'prop-types';
import {intlShape, toast} from '@ecosio/components';
import axios from 'axios';
import React from 'react';
import {processValidationResult} from '../../../../../helpers/validators';
import MfaSetupModalPage from './MfaSetupModalPage';

/**
 * Modal page that checks whether the user has entered a correct password.<br>
 * Its layout is based on the {@link MfaSetupModalPage} component
 * @param {*} props
 * - intl
 * - onSuccess: callback function called once the password verification succeded
 * - onRedirect: callback function called once the password verification succeded but the user is asked for TOTP check as well
 * @returns PasswordVerifier
 */
const PasswordVerifier = ({intl, onSuccess, onRedirect}) => {
  const verifyPassword = async ({password}) => {
    try {
      const response = await axios({
        url: '/api/mfa/verify-password',
        method: 'POST',
        data: {password},
      });
      if (response?.status < 300) {
        onSuccess();
      }
    } catch (err) {
      if (err.response.status >= 300 && err.response.status < 400) {
        return onRedirect();
      }
      if (err.response.status === 400) {
        return processValidationResult(
          {password: 'ENTERED_PASSWORD_INVALID'},
          intl
        );
      }
      console.error(err);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: err.message,
        type: 'error',
      });
    }
  };

  return (
    <>
      <MfaSetupModalPage
        intl={intl}
        title="ENTER_PASSWORD_MODAL_TITLE"
        description="ENTER_PASSWORD_MODAL_DESCRIPTION"
        formOptions={{
          input: {
            name: 'password',
            type: 'password',
            label: 'GENERAL_PASSWORD',
          },
          onSubmit: verifyPassword,
        }}></MfaSetupModalPage>
    </>
  );
};

PasswordVerifier.propTypes = {
  onSuccess: PropTypes.func,
  onRedirect: PropTypes.func,
  intl: intlShape.isRequired,
};

export default PasswordVerifier;

import React, {useState} from 'react';
import {Checkbox, Icon, Input, Form} from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';
import {dynID} from '../../../../helpers/utils';
import {ErrorLabel} from '../../../Login/ErrorLabel';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
`;

const CheckboxListInput = styled.section`
  background-color: white;
  width: 100%;
`;

const ValueList = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  li:nth-child(1) {
    border-top: none;
  }
`;

const ListEntry = styled.li`
  padding: 1rem;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const UserLabelList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const UserLabelStyles = styled.span`
  background-color: #e5e5e5;
  padding: 2px 4px 4px 8px;
  border-radius: 4px;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  letter-spacing: 1px;
  margin-bottom: 4px;
  color: #002268;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

const CheckboxLabel = styled.label`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

/**
 * @param uuids {Array<string> | undefined}
 * @param options {Array<Record<string, string>>}
 * @returns {Array<Record<string, string>>}
 */
function getSelectedNames(uuids, options) {
  if (Array.isArray(uuids)) {
    return uuids
      .map((uuid) => options.find((o) => o.uuid === uuid))
      .filter(Boolean);
  } else {
    return [];
  }
}

const UserLabel = ({children, onRemove}) => {
  return (
    <UserLabelStyles>
      {children}{' '}
      <Icon
        style={{marginRight: '0', cursor: 'pointer', marginLeft: '0.25rem'}}
        name="remove"
        onClick={onRemove}
      />
    </UserLabelStyles>
  );
};

/**
 * @param email {string}
 * @param name {string}
 * @param query {string}
 * @returns {boolean}
 */
function matchesQuery(email, name, query) {
  const q = query.toLowerCase();
  return email.toLowerCase().includes(q) || name.toLowerCase().includes(q);
}

const CheckboxList = ({
  options: originalOptions,
  value,
  onChange,
  label,
  error,
  required,
}) => {
  const [options, setOptions] = useState(originalOptions);
  const [filter, setFilter] = useState('');
  const intl = useIntl();

  const handleChange = (option, checked) => {
    const newValue = checked
      ? [...value, option.uuid]
      : value.filter((p) => p !== option.uuid);
    onChange(newValue);
  };

  const onFilter = (e, {value}) => {
    setFilter(value);
    setOptions(
      originalOptions.filter((o) => matchesQuery(o.email, o.name, value))
    );
  };

  const onRemove = (uuid) => {
    const newValue = value.filter((e) => e !== uuid);
    onChange(newValue);
  };

  return (
    <Wrapper>
      <Form.Field required={required} style={{width: '50%'}}>
        <label>
          <FormattedMessage id={dynID(label)} />
        </label>

        <CheckboxListInput data-spec="checkbox-list-root">
          <Input
            value={filter}
            onChange={onFilter}
            placeholder={intl.formatMessage({
              id: 'PLACEHOLDER_SEARCH',
            })}
            icon="search"
            data-spec="checkbox-list-search"
          />
          <ValueList data-spec="checkbox-list-values">
            {options?.map((o) => (
              <ListEntry
                key={o.key}
                onClick={() => handleChange(o, !value.includes(o.uuid))}
                data-spec={`checkbox-list-entry-${o.uuid}`}>
                <Checkbox
                  checked={value?.includes(o.uuid)}
                  onChange={(e, {checked}) => handleChange(o, checked)}
                  label={
                    <CheckboxLabel>
                      <strong>{o.name}</strong> {o.email}
                    </CheckboxLabel>
                  }
                />
              </ListEntry>
            ))}
          </ValueList>
        </CheckboxListInput>
        <ErrorLabel dontTranslate visible={error} error={error} />
      </Form.Field>
      <section style={{width: '50%'}}>
        <StyledLabel>
          <FormattedMessage
            id="ASSIGNEE_GROUP_SELECTED_USERS_LABEL"
            values={{count: value?.length || 0}}
          />
        </StyledLabel>
        <UserLabelList>
          {getSelectedNames(value, originalOptions).map((member) => (
            <UserLabel onRemove={() => onRemove(member.uuid)} key={member.uuid}>
              {member.name}
            </UserLabel>
          ))}
        </UserLabelList>
      </section>
    </Wrapper>
  );
};

CheckboxList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
};

export default CheckboxList;

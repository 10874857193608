import {Authority} from '@ecosio/auth';
import {CheckBoxField, DropdownField, toast} from '@ecosio/components';
import {HeaderSubHeader} from '@ecosio/components/build/components/ErrorBoundary/ErrorBoundaryStyles';
import {ecosioColors} from '@ecosio/customer-layout';
import React, {useEffect, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Button, Form, Grid, Header} from 'semantic-ui-react';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import {useRequiresAuthority} from '../../Helper/hooks';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../SettingsHeader';
import SettingsPages from '../settingsPages';
import SamlCollapseBox from './SamlCollapseBox/SamlCollapseBox';
import {getRememberMeSettings, updateRememberMeSettings} from './rememberMeApi';

const oneDay = 86400;
const oneWeek = 86400 * 7;
const oneMonth = 86400 * 30;

const validityOptions = (intl) => [
  {text: intl.formatMessage({id: 'ONE_DAY'}), value: oneDay, key: oneDay},
  {text: intl.formatMessage({id: 'ONE_WEEK'}), value: oneWeek, key: oneWeek},
  {text: intl.formatMessage({id: 'ONE_MONTH'}), value: oneMonth, key: oneMonth},
];
const SecurityPage = () => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    enabled: false,
    validityInSeconds: oneWeek,
  });
  const userConfig = useSelector((state) => state.config?.userConfig || {});
  const {rememberMeAllowed, samlConfigEnabled} = userConfig;

  const intl = useIntl();

  useEffect(() => {
    getRememberMeSettings()
      .then((result) => {
        setSettings({
          enabled: result.data?.enabled,
          validityInSeconds: result.data?.validityInSeconds || oneWeek,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useRequiresAuthority(Authority.COMPANY_USER_ADD);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await updateRememberMeSettings(values);
      toast({
        title: intl.formatMessage({id: 'SUBSCRIPTION_SETTINGS_SAVED'}),
        type: 'success',
        time: 4000,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: error.message,
        type: 'error',
        time: 100 * 1000,
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <SettingsPageContainer>
        <SettingsHeader page={SettingsPages.SECURITY} />
        <Helmet>
          <title>{createPageTitle(intl, 'GENERAL_SECURITY')}</title>
        </Helmet>
        {!samlConfigEnabled && !rememberMeAllowed && (
          <Header as="h3" style={{textAlign: 'center'}}>
            <FormattedMessage id="NO_COMPANY_SECURITY_SETTINGS"></FormattedMessage>
          </Header>
        )}
        {samlConfigEnabled && <SamlCollapseBox></SamlCollapseBox>}
        {rememberMeAllowed && (
          <Grid
            style={{
              backgroundColor: `${ecosioColors.greyShades[5]}`,
            }}>
            <Grid.Row columns={1}>
              <Grid.Column width={2} />
              <Grid.Column width={7}>
                <Header as="h2">
                  <Header.Content>
                    <FormattedMessage id="REMEMBER_ME_HEADER" />
                    <Header.Subheader>
                      <HeaderSubHeader>
                        <FormattedMessage id="REMEMBER_ME_SUBHEADER" />
                      </HeaderSubHeader>
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <FinalForm
                  onSubmit={onSubmit}
                  initialValues={settings}
                  render={({handleSubmit, values}) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid columns={'equal'}>
                        <Grid.Row style={{marginTop: 20, minHeight: 100}}>
                          <Grid.Column>
                            <Field
                              helpText={intl.formatMessage({
                                id: 'REMEMBER_ME_ENABLED_LABEL',
                              })}
                              label={intl.formatMessage({
                                id: 'REMEMBER_ME_ENABLED_HELP',
                              })}
                              name="enabled"
                              component={CheckBoxField}
                              labelPosition="left"
                              helpTextProps={{position: 'label'}}
                              toggle
                            />
                          </Grid.Column>
                          {values.enabled && (
                            <Grid.Column>
                              <Field
                                helpText={intl.formatMessage({
                                  id: 'REMEMBER_ME_VALIDITY_LABEL',
                                })}
                                label={intl.formatMessage({
                                  id: 'REMEMBER_ME_VALIDITY_HELP',
                                })}
                                options={validityOptions(intl)}
                                name="validityInSeconds"
                                component={DropdownField}
                                toggle
                                inverted
                              />
                            </Grid.Column>
                          )}
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Button loading={loading} type="submit">
                              <FormattedMessage id="GENERAL_SAVE" />
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </SettingsPageContainer>
    </div>
  );
};

export default SecurityPage;

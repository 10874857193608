import {Button} from 'semantic-ui-react';
import styled from 'styled-components';

export const NextButton = styled(Button)`
  margin: 0px !important;
  width: 180px;
  display: flex !important;
  justify-content: space-between;
  align-self: flex-end;
`;

export const samlOnboardingLayout = {
  position: 'absolute',
  width: '100%',
  top: '0px',
};

import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Provider, useSelector} from 'react-redux';
import {Message} from 'semantic-ui-react';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../SettingsHeader';
import SettingsPages from '../settingsPages';
import {
  MessageInputWrapper,
  WarningIcon,
  SettingsFormWrapper,
} from '../SettingsStyles';
import MfaSettings from './MFA/MfaSettings';
import mfaStore from './MFA/mfaStore';

const UserSecurityPage = (props) => {
  const mfaEnforcedOnCompany = useSelector(
    (state) => state.config?.userConfig?.mfaEnforcedOnCompany
  );
  const signedInViaSaml = useSelector(
    (state) => state.config?.userConfig?.signedInViaSaml
  );

  return (
    <SettingsPageContainer>
      <SettingsHeader page={SettingsPages.ACCOUNT_SECURITY}></SettingsHeader>
      <SettingsFormWrapper>
        {signedInViaSaml && (
          <Message style={{background: '#f2f2f2'}}>
            <MessageInputWrapper>
              <WarningIcon name="warning sign" size="big" />
              <FormattedMessage id="MFA_FOR_CREDENTIALS_AUTH_MSG"></FormattedMessage>
            </MessageInputWrapper>
          </Message>
        )}
        <Provider store={mfaStore}>
          <MfaSettings
            {...props}
            mfaEnforcedOnCompany={mfaEnforcedOnCompany}></MfaSettings>
        </Provider>
      </SettingsFormWrapper>
    </SettingsPageContainer>
  );
};
export default injectIntl(UserSecurityPage);

import React, {useEffect, useState} from 'react';
import {CollapseBox, intlShape, TextContent, toast} from '@ecosio/components';
import {Grid, Header, Button} from 'semantic-ui-react';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import axios from 'axios';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import SettingsHeader from '../SettingsHeader';
import {stringifyQuery} from '../../../helpers/utils';
import SettingsPages from '../settingsPages';
import {getFilenamePattern} from '../../http/companySettings';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import ActivePattern from './ActivePattern';
import AddPattern from './AddPattern';
import AddCustomPattern from './AddCustomPattern';
import {SettingsPageContainer} from './FilePatternStyles';

//I don't like this...
//TODO specific label header inside semantic ui repo
const Label = styled(Header)`
  text-transform: uppercase !important;
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 1px !important;
  font-weight: 700 !important;
`;

export const tokenize = (pattern) => {
  let lastChar = '';
  let currentToken = '';
  let lastClosingBracketIdx = '';
  const tokens = [];
  pattern.split('').map((char, idx) => {
    lastChar = pattern[idx - 1];
    currentToken += char;
    if (lastChar === '$' && char === '{') {
      const s = currentToken.substr(0, currentToken.length - 2);
      if (s.length > 0) {
        tokens.push(s);
      }
      currentToken = '${';
    } else if (char === '}') {
      tokens.push(currentToken);
      currentToken = '';
      lastClosingBracketIdx = idx;
    } else if (pattern.length === idx + 1) {
      const lastToken = pattern.substr(
        lastClosingBracketIdx + 1,
        pattern.length
      );
      tokens.push(lastToken);
    }
  });
  return tokens;
};

export const deTokenize = (tokens) => {
  return tokens.join('');
};

const separators = ['-', '_', '.', '/'];

const FileNamePatternPage = ({intl}) => {
  const [pattern, setPattern] = useState('');
  const [previewPattern, setPreviewPattern] = useState('');
  const [tokens, setTokens] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [data, setData] = useState({});

  const updatePreviewPattern = (tokens) => {
    const pattern = deTokenize(tokens);
    axios
      .get(`/api/company/filename-pattern/preview?${stringifyQuery({pattern})}`)
      .then((res) => {
        setPreviewMode(true);
        setPreviewPattern(res.data);
        setTokens(tokens);
      })
      .catch((e) => {
        console.error(e);
        toast({
          title: intl.formatMessage({id: 'GENERAL_ERROR'}),
          description: e.message,
          type: 'error',
        });
      });
  };

  const mountPreviewPattern = (pattern) => {
    axios
      .get(`/api/company/filename-pattern/preview?${stringifyQuery({pattern})}`)
      .then((res) => {
        setPreviewPattern(res.data);
      })
      .catch((e) => {
        toast({
          title: intl.formatMessage({id: 'GENERAL_ERROR'}),
          description: e.message,
          type: 'error',
        });
        console.error(e);
      });
  };

  const onHandleAddToken = (token, isSeperator) => {
    const newTokens = tokens.slice();
    if (!isSeperator) {
      newTokens.push(`\${${token}}`);
    } else {
      newTokens.push(`${token}`);
    }
    updatePreviewPattern(newTokens);
    setPattern(deTokenize(newTokens));
  };

  const onHandleDeleteToken = (tokenIdx) => {
    const newTokens = tokens.filter((token, idx) => tokenIdx !== idx);
    updatePreviewPattern(newTokens);
    setPattern(deTokenize(newTokens));
  };

  const onHandleSubmit = () => {
    if (!pattern) {
      toast({
        title: intl.formatMessage({
          id: 'FILENAME_PATTERN_REQUIRED_TEXT',
        }),
        type: 'error',
        time: 5 * 1000,
      });
    } else {
      axios
        .post('/api/company/filename-pattern', {
          filenamePattern: pattern,
          ...data,
        })
        .then((res) => {
          setData({version: res.data.version, keywords: res.data.keywords});
          toast({
            title: intl.formatMessage({
              id: 'FILENAME_PATTERN_SAVED_SUCCESS',
            }),
            type: 'success',
            time: 5 * 1000,
          });
        })
        .catch((e) => {
          toast({
            title: intl.formatMessage({
              id: '500_SERVER_ERROR',
            }),
            description: intl.formatMessage({
              id: e.message,
            }),
            type: 'error',
          });
        });
    }
  };

  useEffect(() => {
    if (!previewMode) {
      getFilenamePattern()
        .then((res) => {
          setPattern(res.data.filenamePattern);
          setData({
            uuid: res.data.uuid,
            version: res.data.version,
            keywords: res.data.keywords,
          });
        })
        .catch((e) => console.error(e));
      if (pattern) {
        const tokens = tokenize(pattern);
        setTokens(tokens);
        mountPreviewPattern(pattern);
      }
    }
  }, [pattern, previewMode]);

  return (
    <SettingsPageContainer>
      <Helmet>
        <title>{createPageTitle(intl, 'ADVANCED_PAGE_TITLE')}</title>
      </Helmet>
      <SettingsHeader page={SettingsPages.ADVANCED} />
      <CollapseBox
        header={<Msg id="FILE_PATTERN_HEADER" />}
        style={{marginTop: '40px'}}>
        <Grid>
          <Grid.Row>
            <Grid.Column width="8">
              <Msg id="FILE_PATTERN_HEADER_MESSAGE" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <Grid>
                <Grid.Row>
                  <Grid.Column width="14" data-spec="preview">
                    <TextContent
                      code
                      copy={false}
                      value={previewPattern}
                      label={intl.formatMessage({
                        id: 'FILENAME_PATTERN_PREVIEW_LABEL',
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="14">
                    <Label>
                      <Msg id="FILENAME_PATTERN_ACTIVE_LABEL" />
                    </Label>
                    <ActivePattern
                      tokens={tokens}
                      onHandleDeleteToken={onHandleDeleteToken}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width="8">
              <Grid>
                <Grid.Row>
                  <Grid.Column width="14" data-spec="add-token">
                    <Label style={{fontSize: '12px'}}>
                      <Msg id="FILENAME_PATTERN_ADD_VARIABLE" />
                    </Label>
                    <AddPattern
                      isSeperator={false}
                      keywords={data.keywords}
                      onHandleAddToken={onHandleAddToken}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column data-spec="add-separator">
                    <Label style={{fontSize: '12px'}}>
                      <Msg id="FILENAME_PATTERN_ADD_SEPERATOR" />
                    </Label>
                    <AddPattern
                      keywords={separators}
                      isSeperator
                      onHandleAddToken={onHandleAddToken}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Label style={{fontSize: '12px'}}>
                      <Msg id="FILENAME_PATTERN_ADD_CUSTOM_CONSTANT" />
                    </Label>
                    <AddCustomPattern
                      onHandleAddToken={onHandleAddToken}
                      intl={intl}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={onHandleSubmit}
                floated="right"
                style={{marginBottom: '-20px', marginRight: '-20px'}}>
                <Msg id="GENERAL_SUBMIT" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </CollapseBox>
    </SettingsPageContainer>
  );
};

FileNamePatternPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(FileNamePatternPage);

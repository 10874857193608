import {ecosioBlues} from '@ecosio/customer-layout/build/styles/colorConstants';
import PropTypes from 'prop-types';
import {default as React} from 'react';
import {FormattedMessage} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import {InfoMessageWrapper} from '../InfoMessageWrapper';

const InfoBox = styled.div`
  background-color: ${() => ecosioBlues[6]};
  padding: 1.5rem 1.5rem;
  display: flex;
  color: black;
  div + * {
    margin-left: 1rem;
  }
`;

const InfoMessageBox = ({messageId}) => {
  return (
    <InfoMessageWrapper data-spec="allow-credentials-info-box">
      <InfoBox>
        <div>
          <Icon
            name="info circle"
            size="big"
            style={{color: ecosioBlues[1]}}></Icon>
        </div>

        <FormattedMessage id={messageId}></FormattedMessage>
      </InfoBox>
    </InfoMessageWrapper>
  );
};

InfoMessageBox.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default InfoMessageBox;

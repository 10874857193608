import {
  AutoSave,
  CheckBoxField,
  CollapseBox,
  DropdownField,
  intlShape,
} from '@ecosio/components';
import axios from 'axios';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {Helmet} from 'react-helmet';
import {
  FormattedMessage,
  FormattedMessage as Msg,
  injectIntl,
} from 'react-intl';
import {connect, useSelector} from 'react-redux';
import {Form, Header, Icon, Loader, Message} from 'semantic-ui-react';

import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../SettingsHeader';
import SettingsPages from '../settingsPages';
import {SettingsFormWrapper} from '../SettingsStyles';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import ExternalNotifications from './ExternalNotifications';
import {
  Column,
  ColumnHeader,
  Container,
  FlexRow,
  HeaderRow,
  SubmitText,
  SubscriptionList,
  SubscriptionRow,
} from './styledComponents';

const categoryOptions = [
  {
    value: 'ALL',
    text: 'ALL_CATEGORIES',
    key: 'ALL',
  },
  {
    value: 'MINE',
    text: 'MY_CATEGORIES',
    key: 'MINE',
  },
];

const PlainTextField = ({input}) => {
  return <span style={{marginLeft: '0.5rem'}}>{input.value}</span>;
};

PlainTextField.propTypes = {
  input: PropTypes.shape({value: PropTypes.string}),
};

const SubscriptionForm = ({
  subscription,
  onSubmit,
  isOfferingCompany,
  hasCategories,
  userEmails,
}) => {
  const [submittedRecently, setSubmittedRecently] = React.useState(false);

  const handleSubmit = (values) => {
    onSubmit(values);
    setSubmittedRecently(true);

    window.setTimeout(() => {
      setSubmittedRecently(false);
    }, 4000);
  };

  const showEmailsDropdown = userEmails.length > 1;

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={subscription}
      render={() => {
        const isDisabled = subscription.notificationConfigurations[0].disabled;
        const toggleDisabled = !subscription.canBeDisabled;
        return (
          <FlexRow>
            <AutoSave debounce={500} />
            <SubscriptionRow>
              <Column width="50%">
                <Msg
                  id={`${isOfferingCompany ? 'PROVIDING' : 'USING'}_EVENT_${
                    subscription.event
                  }`}
                />
              </Column>
              <Column width="auto">
                <div className="ui form" style={{marginTop: '0'}}>
                  <Form.Group style={{margin: '0', alignItems: 'center'}}>
                    <Field
                      name="notificationConfigurations[0].disabled"
                      component={CheckBoxField}
                      toggle
                      inverted
                      disabled={toggleDisabled}
                    />
                    {showEmailsDropdown ? (
                      <Field
                        name="notificationConfigurations[0].to[0]"
                        component={DropdownField}
                        options={userEmails}
                        disabled={isDisabled}
                      />
                    ) : (
                      <Field
                        name="notificationConfigurations[0].to[0]"
                        component={PlainTextField}
                        disabled
                      />
                    )}
                    {hasCategories && (
                      <Field
                        name="categoryFilter"
                        component={DropdownField}
                        options={categoryOptions}
                        disabled={isDisabled}
                        translated
                      />
                    )}
                  </Form.Group>
                </div>
              </Column>
            </SubscriptionRow>
            {submittedRecently && (
              <SubmitText>
                <Icon name="check circle outline" />{' '}
                <Msg id="SUBSCRIPTION_SETTINGS_SAVED" />
              </SubmitText>
            )}
          </FlexRow>
        );
      }}
    />
  );
};

SubscriptionForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOfferingCompany: PropTypes.bool.isRequired,
  hasCategories: PropTypes.bool.isRequired,
  userEmails: PropTypes.array.isRequired,
};

const ModuleSubscriptions = ({
  subscriptions,
  isOfferingCompany,
  onSubmit,
  hasCategories,
  userEmails,
}) => {
  return (
    <SubscriptionList>
      <HeaderRow>
        <Column>
          <ColumnHeader>
            <Msg id="NOTIFICATION_TYPE" />
          </ColumnHeader>
        </Column>

        <Column>
          <ColumnHeader>
            <Msg id="NOTIFICATION_TYPE_EMAIL" />
          </ColumnHeader>
        </Column>
      </HeaderRow>
      {subscriptions.map((subscription, idx) => (
        <SubscriptionForm
          subscription={subscription}
          key={idx}
          onSubmit={onSubmit}
          isOfferingCompany={isOfferingCompany}
          hasCategories={hasCategories}
          userEmails={userEmails}
        />
      ))}
    </SubscriptionList>
  );
};

ModuleSubscriptions.propTypes = {
  subscriptions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOfferingCompany: PropTypes.bool.isRequired,
  hasCategories: PropTypes.bool.isRequired,
  userEmails: PropTypes.array.isRequired,
};

const mapToStateProps = ({config, locales}) => ({
  userConfig: config.userConfig,
  locale: locales.locale,
});

@connect(mapToStateProps)
class SubscriptionSettings extends Component {
  static propTypes = {
    userConfig: PropTypes.object.isRequired,
    intl: intlShape,
  };

  state = {data: {}, error: null, userEmails: []};

  // eslint-disable-next-line react/sort-comp
  saveData = (data) => {
    this.setState({
      data: data,
    });
  };

  componentDidMount() {
    axios
      .get('/api/subscription')
      .then((res) => this.saveData(res.data))
      .catch((err) => {
        console.error(err);
        this.setState({error: err});
      });
    axios
      .get('/api/user/emails')
      .then((res) =>
        this.setState({
          userEmails: res.data.secondaryEmailAddresses
            .filter((e) => Boolean(e.verifiedAt))
            .map((e) => ({
              text: e.email,
              value: e.email,
              key: e.email,
            })),
        })
      )
      .catch((err) => {
        console.error(err);
        this.setState({error: err});
      });
  }

  onSubmit = (values) => {
    return axios
      .put('/api/subscription', values)
      .then((res) => this.saveData(res.data))
      .catch((err) => {
        this.setState({error: err});
        console.error(err);
      });
  };

  render() {
    const {data, error, userEmails} = this.state;
    const {userConfig, intl} = this.props;
    const {subscriptions, hasCategories} = data;

    const noSubscriptions = isEmpty(subscriptions);

    if (typeof subscriptions === 'undefined') {
      return <Loader active />;
    }

    const subscriptionsContent = (
      <>
        {error && (
          <Message error>
            <Message.Header>
              <Msg id="GENERAL_ERROR" />
            </Message.Header>
            <p>{error.message}</p>
          </Message>
        )}
        <Container>
          {Object.entries(subscriptions).map(([moduleName, subs]) => (
            <CollapseBox
              style={{margin: '0px 0px 16px 0'}}
              key={moduleName}
              relativePosition
              collapsible
              initiallyCollapsed
              header={<Msg id={`GENERAL_${moduleName}`} />}>
              <ModuleSubscriptions
                subscriptions={subs}
                isOfferingCompany={userConfig.offeringCompany}
                onSubmit={this.onSubmit}
                hasCategories={hasCategories}
                moduleName={moduleName}
                userEmails={userEmails}
              />
            </CollapseBox>
          ))}
        </Container>
      </>
    );

    return (
      <>
        <Helmet>
          <title>
            {createPageTitle(intl, 'SUBSCRIPTION_SETTINGS_PAGE_TITLE')}
          </title>
        </Helmet>

        <Header>
          <FormattedMessage id="INTERNAL_NOTIFICATIONS_TITLE" />
        </Header>

        {noSubscriptions ? (
          <CollapseBox
            style={{margin: '0px 0px 16px 0'}}
            key={'INTERNAL_NOTIFICATIONS'}
            relativePosition
            initiallyCollapsed
            header={<Msg id="NO_INTERNAL_SUBSCRIPTIONS" />}></CollapseBox>
        ) : (
          subscriptionsContent
        )}
      </>
    );
  }
}

const SubscriptionPage = (props) => {
  const companyType = useSelector(
    (state) => state.config?.userConfig?.company?.companyType
  );

  return (
    <SettingsPageContainer>
      <SettingsHeader page={SettingsPages.NOTIFICATIONS} />
      <SettingsFormWrapper>
        <SubscriptionSettings {...props} />
        {companyType === 'STANDARD' && (
          <ExternalNotifications {...props}></ExternalNotifications>
        )}
      </SettingsFormWrapper>
    </SettingsPageContainer>
  );
};

export default injectIntl(SubscriptionPage);

import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {Button, Form as SemForm, Grid} from 'semantic-ui-react';
import {Field, Form} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import htmlParse from 'html-react-parser';
import {InputField} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import {Link} from 'react-router-dom';
import ErrorComponent from '../Helper/ErrorComponent';
import {
  processValidationResult,
  translateValidationResult,
} from '../../helpers/validators';
import {createPageTitle} from '../../helpers/helmetHelpers';
import {
  CenterContent,
  HeaderWrapper,
  LoginContainer,
  NeedHelp,
  SamlLogo,
  SecondaryLoginExternalLink,
  SubHeader,
  WelcomeActionsWrapper,
  WelcomeWrapper,
} from './LoginPageStyles';
import {samlLogin} from './loginApi';
import SamlLoginFailedError from './SamlLoginFailedError';
import {LOGIN_URL} from './LoginPage';

const SubmitError = () => {
  return (
    <ErrorComponent
      errorContent={
        <FormattedMessage id="500_SERVER_ERROR">
          {(chunks) => htmlParse(chunks.join(''))}
        </FormattedMessage>
      }
      errorHeader={<FormattedMessage id="500_SERVER_ERROR_DESCRIPTION" />}
    />
  );
};

const SamlLoginForm = () => {
  const intl = useIntl();

  const [formError, setFormError] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);

  const onFormSubmit = async (values) => {
    try {
      setSubmitting(true);
      const response = await samlLogin(values);
      if (!response.headers['location']) {
        console.error('No location header received from /saml2/commence');
        setFormError(true);
      } else {
        const redirectUrl = response.headers['location'];
        window.location.href = redirectUrl;
      }
    } catch (error) {
      if (error.response?.status === 400) {
        const validationResults = processValidationResult(
          error.response.data,
          intl
        );

        setSubmitting(false);
        return translateValidationResult(validationResults, intl);
      } else {
        console.error(error);
        setFormError(true);
      }
      // We only set submitting back to false when an error occurs
      // For the successful submission of the form, we shouldn't re-enable the submit button once the form is submitted
      // Even after the form is submitted, we will still be on this page while the redirectUrl is loaded
      // Thus, if we would re-enable the button once the form is submitted, the user could click it again, while redirection loads
      // Therefore, once the submit button was clicked, and there was no error, the button will remain disable
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>{formError && <SubmitError />}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form
            onSubmit={onFormSubmit}
            render={({handleSubmit}) => (
              <SemForm onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Field
                        name="email"
                        type="email"
                        component={InputField}
                        label={intl.formatMessage({id: 'GENERAL_EMAIL'})}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid.Row>
                  <Grid.Column>
                    <SecondaryLoginExternalLink
                      data-spec="to-ecosio-login"
                      href={LOGIN_URL}>
                      <FormattedMessage id="TO_ECOSIO_LOGIN" />
                    </SecondaryLoginExternalLink>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{marginTop: 35}}>
                  <Grid.Column>
                    <Link
                      to="/login"
                      style={{
                        color: ecosioColors.ecosioBlues[0],
                        display: 'inline-block',
                        marginTop: 3,
                        fontSize: 12,
                      }}>
                      <FormattedMessage id="BACK_TO_ACCOUNTS" />
                    </Link>
                    <Button
                      type="submit"
                      disabled={submitting}
                      loading={submitting}
                      style={{
                        float: 'right',
                        marginRight: -25,
                        paddingRight: 50,
                      }}>
                      <FormattedMessage id="SAML_LOGIN_START" />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </SemForm>
            )}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const SamlLoginPage = () => {
  const intl = useIntl();

  const searchParams = new URLSearchParams(window.location.search);
  const hasAuthenticationError = searchParams.has('error');

  return (
    <LoginContainer className="saml-login-container">
      <Helmet>
        <title>{createPageTitle(intl, 'SAML_LOGIN_HEADER')}</title>
        <description>
          {intl.formatMessage({id: 'SAML_LOGIN_DESCRIPTION'})}
        </description>
      </Helmet>
      <CenterContent>
        <WelcomeWrapper>
          {hasAuthenticationError && (
            <div className="saml-login-error">
              <SamlLoginFailedError />
            </div>
          )}
        </WelcomeWrapper>
        <div className="saml-login-form">
          <WelcomeWrapper>
            <div>
              <a
                style={{textAlign: 'center', display: 'block', marginTop: 30}}
                href="https://www.ecosio.com"
                target="_blank"
                rel="noopener noreferrer">
                {/*TODO: upload to files.ecosio.com*/}
                <SamlLogo
                  id="ecosio-saml-logo"
                  src="https://assets.ecosio.com/98.0.0-beta.15/monitor/themes/monitor/assets/images/Logo.png"
                  alt="ecosio logo"
                />
              </a>
            </div>
            <HeaderWrapper>
              <SubHeader style={{marginTop: 20, marginBottom: '0rem'}}>
                <FormattedMessage id="SAML_LOGIN_DESCRIPTION">
                  {(chunks) => htmlParse(chunks.join(''))}
                </FormattedMessage>
              </SubHeader>
            </HeaderWrapper>
            <WelcomeActionsWrapper>
              <SamlLoginForm />
            </WelcomeActionsWrapper>
          </WelcomeWrapper>
        </div>
        <NeedHelp>
          <a href="mailto:support@ecosio.com?subject=Help with ecosio SSO">
            <FormattedMessage id="NEED_HELP" />
          </a>
        </NeedHelp>
      </CenterContent>
    </LoginContainer>
  );
};

export default SamlLoginPage;

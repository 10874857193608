import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import {FormattedMessage as Msg} from 'react-intl';
import {determineEnvironmentFromLocation} from '@ecosio/components';
import {STATIC_ASSETS_BASE} from '../../../constants';

// we only want a single chat window open at any time
let windowReference = null;

// simplify testing
export const resetReference = () => (windowReference = null);

export const CRISP_MONITOR_APP_ID = '92369b90-e436-4e39-8e48-8857fe2a030b';
export const CRISP_MONITOR_APP_DEV_ID = '81451859-99c6-40af-962c-4ec0c21cef47';

export const getWebSiteId = (env) => {
  // test and prod share the same website in crisp, this is intentional
  return env === 'prod' || env === 'test'
    ? CRISP_MONITOR_APP_ID
    : CRISP_MONITOR_APP_DEV_ID;
};
/**
 * Opens the crisp customer chat app in new window.
 * https://help.crisp.chat/en/article/how-can-i-embed-the-crisp-chatbox-in-an-external-link-or-an-iframe-bkfh98/
 *
 * The chat_window.html file which is opened by this function is a plain html file, located
 * in the assets folder of the accounts app.
 *
 * It contains vanilla javascript to extract 2 parameters from the URL and pass it on to
 * the chat iframe which is sandboxed:
 *
 * - website_id <- the token from crisp to identify our app (is the same on dev/test)
 * - user_email <- the userId from our user, used as a correlator over multiple chats on crisp.
 */
export const openChatWindow = (authServer, userId) => {
  if (windowReference !== null && !windowReference.closed) {
    windowReference.focus();
    return false;
  }

  const env = determineEnvironmentFromLocation(window?.location?.href);

  if (!env) {
    console.warn('No environment set, cannot open chat window');
    return false;
  }

  const websiteId = getWebSiteId(env);
  const params = {website_id: websiteId};

  if (userId) {
    params.user_email = userId;
  }

  const location = `${STATIC_ASSETS_BASE}/assets/chat_window.html?${qs.stringify(
    params
  )}`;

  // https://developer.mozilla.org/en-US/docs/Web/API/Window/open#window_functionality_features
  // TODO: dimensions could probably be calculated based on screen dimensions
  windowReference = window.open(location, '_blank', 'width=600,height=800');

  return false;
};

const SupportLink = ({userId}) => (
  <a
    data-spec="crisp-link"
    href="#"
    onClick={openChatWindow.bind(this, userId)}>
    <Msg id="GENERAL_SUPPORT" />
  </a>
);

SupportLink.defaulProps = {
  supportAppId: null,
  userId: null,
};

SupportLink.propTypes = {
  /**
   * If a supportAppId is provided, support link will render a link to our external support app.
   */
  supportAppId: PropTypes.string,
  /**
   * userId which will be passed to the external support app
   */
  userId: PropTypes.string,
};
export default SupportLink;

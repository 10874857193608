import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {ecosioColors} from '@ecosio/customer-layout';

const MessageWrapper = styled.div`
  color: ${ecosioColors.greyShades[1]};
  font-size: 10px;
  font-weight: 700;
  margin: 0 0 0.28571429rem 0;
  text-transform: uppercase;
`;

const Label = ({messageId, style}) => {
  return (
    <MessageWrapper style={style}>
      <FormattedMessage id={messageId}></FormattedMessage>
    </MessageWrapper>
  );
};

Label.propTypes = {
  messageId: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default Label;

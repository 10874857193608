import React from 'react';
import {Button, Form, Grid, Icon, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';
import {Field, Form as FinalForm} from 'react-final-form';
import {InputField} from '@ecosio/components';
import MonitorModalContent from '../../Modals/MonitorModalContent';
import MonitorModalActionButtonFooter from '../../Modals/MonitorModalActionButtonFooter';
import {
  getBackendPermissions,
  getPermissionsFormObject,
  PermissionsCheckboxes,
} from '../../Helper/Permissions';
import {requiredEmail} from '../../../helpers/validators';

const actionButtonStyle = {
  textAlign: 'left',
};

const buttonIconStyle = {
  float: 'right',
  marginLeft: 20,
};

const EditRelationPermissionsModal = ({
  open,
  userId,
  permissions,
  onClose,
  onChange,
  onDelete,
  loading,
}) => {
  const intl = useIntl();
  const submit = (values) => {
    const newPermissions = getBackendPermissions(values);
    onChange(newPermissions);
  };

  const initialValues = getPermissionsFormObject(permissions);
  initialValues.email = userId;

  return (
    <Modal open={open} size="small" data-spec="partner-relation-edit-modal">
      <MonitorModalContent
        onClose={onClose}
        header={<FormattedMessage id="EDIT_PARTNER_RELATION_USER" />}
        subHeader={<FormattedMessage id="EDIT_PARTNER_RELATION_USER_DESC" />}>
        <FinalForm
          initialValues={initialValues}
          onSubmit={submit}
          render={({handleSubmit}) => (
            <Form
              onSubmit={handleSubmit}
              style={{marginTop: 0}}
              data-spec="edit-partner-user-form">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <div style={{marginBottom: '20px'}}>
                      <Field
                        name={`email`}
                        fluid
                        disabled
                        autofocus
                        translateError
                        component={InputField}
                        validate={requiredEmail}
                        label={intl.formatMessage({id: 'GENERAL_EMAIL'})}
                        placeholder="john.doe@example.com"
                        required
                        data-spec={`partner-email`}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <PermissionsCheckboxes />
                  </Grid.Column>
                </Grid.Row>
                <MonitorModalActionButtonFooter>
                  <Button
                    type="button"
                    color="red"
                    style={{float: 'left'}}
                    onClick={onDelete}>
                    <FormattedMessage id={'GENERAL_DELETE'} />
                  </Button>
                  <Button
                    disabled={loading}
                    loading={loading}
                    data-spec="save"
                    type="submit"
                    style={Object.assign(
                      {float: 'right', margin: 0, width: 'auto'},
                      actionButtonStyle
                    )}>
                    <FormattedMessage id="GENERAL_SUBMIT" />
                    <Icon
                      name="chevron right"
                      style={Object.assign({}, buttonIconStyle)}
                    />
                  </Button>
                </MonitorModalActionButtonFooter>
              </Grid>
            </Form>
          )}
        />
      </MonitorModalContent>
    </Modal>
  );
};

EditRelationPermissionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  userId: PropTypes.string.isRequired,
};
export default EditRelationPermissionsModal;

import {Label, Transition} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const ErrorLabel = ({
  visible,
  error,
  className,
  dontTranslate = false,
}) => {
  return (
    <Transition visible={Boolean(visible)} animation="fade down">
      <Label className={className} basic color="red">
        {error && !dontTranslate ? <FormattedMessage id={error} /> : ''}
        {error && dontTranslate ? error : ''}
      </Label>
    </Transition>
  );
};

ErrorLabel.defaultProps = {
  error: '',
};

ErrorLabel.propTypes = {
  visible: PropTypes.any,
  error: PropTypes.string,
  className: PropTypes.string,
  dontTranslate: PropTypes.bool,
};

import React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {Form as FinalForm, Field} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link, useHistory} from 'react-router-dom';
import {
  AutoSave,
  DataGrid,
  DropdownField,
  InputField,
  Pagination,
} from '@ecosio/components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../SettingsHeader';
import {InnerContainer} from '../Team/styledComponents';
import {Avatar, AvatarList, AvatarStyles} from '../Team/Helpers';
import RelativeTime from '../../Helper/RelativeTime';
import {fullNameFromEmail} from '../../Helper/names';
import {useQueryParams} from '../../../hooks/useQueryParams';
import {stringifyQuery} from '../../../helpers/utils';
import {DIRECTION_CONVERTER_OUT} from '../../Helper/sort';
import {MainCategory, trackEvent} from '../../../analytics';
import {useUserConfig} from '../../../hooks/useUserConfig';
import {
  AssigneeGroupAuthority,
  useAssigneeGroups,
  useRedirectIfNoPermission,
} from './hooks';

const MAX_MEMBERS = 5;

const GroupMembers = ({members, disabled}) => {
  const tooManyMembers = members.length > MAX_MEMBERS;
  // todo add popup with additional members?
  const additionalMembers = members.length - MAX_MEMBERS;

  return (
    <AvatarList>
      {members.slice(0, MAX_MEMBERS).map((m) => (
        <Avatar
          key={m.uuid}
          firstName={m.firstName}
          lastName={m.lastName}
          email={m.email}
          disabled={disabled}
        />
      ))}
      {tooManyMembers && (
        <AvatarStyles disabled={disabled}>+{additionalMembers}</AvatarStyles>
      )}
    </AvatarList>
  );
};

GroupMembers.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string.isRequired,
    })
  ),
};

const DateDisplay = ({date, user}) => {
  return (
    <>
      <span style={{fontSize: '13px'}}>
        <RelativeTime value={date} />
      </span>
      <br />
      <span style={{fontSize: '10px'}}>
        <FormattedMessage
          id="CHANGED_BY"
          values={{user: fullNameFromEmail(user)}}
        />
      </span>
    </>
  );
};

const DetailsLink = styled(Link)`
  width: 48px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f6ff;

  &:hover {
    background-color: #e5edff;
  }
`;

const FilterForm = styled.form`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  background-color: #f8f8f8;
  padding: 0.75rem;

  .field:nth-child(1) {
    width: 100%;
    flex-grow: 1;
  }

  .field:nth-child(1) .ui.icon.input {
    width: 100%;
  }
`;

const convertUrlValues = (values) => {
  let status = 'enabled';
  if (values.disabled === 'true') {
    status = 'disabled';
  }

  return {
    fulltext: values.fulltext,
    status,
  };
};

const convertFormValues = (values) => {
  const filter = {fulltext: values.fulltext};
  if (values.status === 'disabled') {
    filter.disabled = 'true';
  }
  return filter;
};

const AssigneeGroupFilter = () => {
  const searchParams = useQueryParams();
  const mineSelected = searchParams.mine === 'true';
  const intl = useIntl();
  const history = useHistory();
  const userConfig = useUserConfig();

  const onSubmit = (values) => {
    const filter = convertFormValues(values);
    const search = stringifyQuery(filter);
    history.push({search});
  };

  const trackAnalytics = (event) => {
    trackEvent(event, {
      mainCategory: MainCategory.ElementClick,
      companyUuid: userConfig.company.uuid,
      companyName: userConfig.company.name,
    });
  };

  const trackFilterAnalytics = (event) => {
    trackEvent(event, {
      mainCategory: MainCategory.FilterClick,
      companyUuid: userConfig.company.uuid,
      companyName: userConfig.company.name,
    });
  };

  return (
    <section>
      <div className="ui pointing secondary menu">
        <Link
          className={`item ${!mineSelected ? 'active' : ''}`}
          onClick={() => trackAnalytics('All groups tab')}
          to={{search: stringifyQuery({...searchParams, mine: 'false'})}}>
          <FormattedMessage id="ASSIGNEE_GROUPS_FILTER_ALL" />
        </Link>

        <Link
          className={`item ${mineSelected ? 'active' : ''}`}
          onClick={() => trackAnalytics('My groups tab')}
          to={{search: stringifyQuery({...searchParams, mine: 'true'})}}>
          <FormattedMessage id="ASSIGNEE_GROUPS_FILTER_MINE" />
        </Link>
      </div>

      <FinalForm
        initialValues={convertUrlValues(searchParams)}
        onSubmit={onSubmit}>
        {({handleSubmit}) => (
          <FilterForm onSubmit={handleSubmit}>
            <Field
              render={InputField}
              name="fulltext"
              onBlur={() => trackFilterAnalytics('Search groups')}
              placeholder={intl.formatMessage({
                id: 'ASSIGNEE_GROUP_FULL_TEXT_SEARCH',
              })}
              icon="search"
              style={{width: '100%'}}
            />

            <Field
              render={DropdownField}
              onBlur={() =>
                trackFilterAnalytics('Enabled/Disabled group filter')
              }
              name="status"
              options={[
                {text: 'Enabled', value: 'enabled'},
                {text: 'Disabled', value: 'disabled'},
              ]}
            />

            <AutoSave debounce={500} />
          </FilterForm>
        )}
      </FinalForm>
    </section>
  );
};

const AssigneeGroupsPage = ({history}) => {
  useRedirectIfNoPermission(AssigneeGroupAuthority.READ);

  const searchParams = useQueryParams();
  const query = useAssigneeGroups(searchParams);
  const groups = query.data?.content || [];
  const userConfig = useUserConfig();

  const onSort = ({col, dir}) => {
    const sortOrder = DIRECTION_CONVERTER_OUT[dir];

    const newParams = {
      ...searchParams,
      sort: `${col},${sortOrder}`,
    };

    history.push({search: `?${stringifyQuery(newParams)}`});
  };

  // eslint-disable-next-line radar/no-identical-functions
  const trackAnalytics = (event) => {
    trackEvent(event, {
      mainCategory: MainCategory.ElementClick,
      companyUuid: userConfig.company.uuid,
      companyName: userConfig.company.name,
    });
  };

  const dataGridConfig = {
    idSelector: 'uuid',
    fields: [
      {
        id: 'name',
        label: 'ASSIGNEE_GROUP_NAME',
        render: (Table, value) => <Table.Cell>{value}</Table.Cell>,
        sortable: true,
      },
      {
        id: 'members',
        label: 'ASSIGNEE_GROUP_MEMBERS',
        render: (Table, value, group) => (
          <Table.Cell>
            <GroupMembers disabled={group.disabled} members={value} />
          </Table.Cell>
        ),
      },
      {
        id: 'externalCompany.label',
        label: 'GENERAL_COMPANY',
        sortable: true,
        render: (Table, value) => <Table.Cell>{value}</Table.Cell>,
      },
      {
        id: 'description',
        label: 'GENERAL_DESCRIPTION',
        render: (Table, value) => <Table.Cell>{value}</Table.Cell>,
      },
      {
        id: 'creationDate',
        label: 'GENERAL_CREATION_DATE',
        sortable: true,
        render: (Table, value, group) => (
          <Table.Cell>
            <DateDisplay date={value} user={group.createdBy} />
          </Table.Cell>
        ),
      },
      {
        id: 'lastChange',
        label: 'GENERAL_LAST_CHANGE',
        sortable: true,
        render: (Table, value, group) => (
          <Table.Cell>
            <DateDisplay date={value} user={group.lastChangedBy} />
          </Table.Cell>
        ),
      },
      {
        id: 'uuid',
        label: 'EMPTY_STRING',
        translated: false,
        render: (Table, value) => (
          <Table.Cell style={{padding: '0'}} collapsing>
            <DetailsLink
              to={`/settings/assignee-groups/${value}/edit`}
              onClick={() => trackAnalytics('Edit group')}>
              <Icon name="caret right" />
            </DetailsLink>
          </Table.Cell>
        ),
      },
    ],
  };

  return (
    <SettingsPageContainer fullWidth className="settings-container">
      <InnerContainer>
        <SettingsHeader
          page="assignee-groups"
          sideContent={
            <Button
              as={Link}
              to="/settings/assignee-groups/create"
              onClick={() => {
                trackEvent('Create new group', {
                  mainCategory: MainCategory.ButtonClick,
                  companyUuid: userConfig?.company?.uuid,
                  companyName: userConfig?.company?.name,
                });
              }}>
              <Icon name="users" />
              <FormattedMessage id="NEW_ASSIGNEE_GROUP_BUTTON" />
            </Button>
          }
        />
      </InnerContainer>

      <AssigneeGroupFilter />

      <DataGrid
        config={dataGridConfig}
        celled={false}
        loading={query.isLoading}
        translated
        emptyState={{
          icon: 'users',
          header: 'NO_ASSIGNEE_GROUPS_FOUND',
          subHeader: 'NO_ASSIGNEE_GROUPS_FOUND_SUB',
        }}
        data={groups}
        rowFormatter={(row) => ({
          disabled: row.disabled,
        })}
        onSort={onSort}
        renderFooter={({Table, cols}) => (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={cols}>
                <Pagination paging={query.data} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      />
    </SettingsPageContainer>
  );
};

export default AssigneeGroupsPage;

import {
  CollapseBox,
  InternalError,
  PageNotFound,
  toast,
} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import {useQuery} from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Button, Header, Icon, Loader} from 'semantic-ui-react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import htmlParse from 'html-react-parser';
import {SettingsPageContainer} from '../../FileNamePatternPage/FilePatternStyles';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import SettingsHeader from '../../SettingsHeader';
import {SettingsFormWrapper} from '../../SettingsStyles';
import SettingsPages from '../../settingsPages';
import {
  deleteSamlIdp,
  fetchSamlIdp,
  handleGeneralIdpRequestError,
  handleIdpSaverError,
  updateIdpSettings,
} from '../api/samlConfigApi';

import ConfirmationModal from '../../../Modals/ConfirmationModal/ConfirmationModal';
import SamlConfigForm from '../components/SamlConfigForm';

const DeleteButton = styled(Button)`
  background-color: ${ecosioColors.errorRed} !important;
  display: flex;
  justify-content: space-between;

  span + i {
    margin-left: 2rem !important;
    font-size: 1rem;
  }
`;

const prepareFormData = (samlIdp) => {
  if (!samlIdp) {
    return {};
  }
  const samlForm = {
    providerName: samlIdp.providerName,
    domainIdentifiers: samlIdp.domainIdentifiers,
    metadataUrl: samlIdp.metadataUrl,
    email: samlIdp.attributesMapping?.email,
    firstName: samlIdp.attributesMapping?.firstName,
    lastName: samlIdp.attributesMapping?.lastName,
    rootCompanyUuid: samlIdp.companyUuid,
    credentialsAuthAllowed: samlIdp.credentialsAuthAllowed,

    COMPANY_ADMIN: samlIdp.companyAdminPermission,
    PARTNER_MATRIX: samlIdp.partnerMatrixPermission,
    MONITOR_MESSAGING_OPERATIONS: samlIdp.messagingOperationsPermission,
    ELENA_SUPPORT_TICKETS: samlIdp.supportTicketsPermission,
    version: samlIdp.version,
  };

  if (samlIdp.companyMappingConfig) {
    samlForm.companyMappingConfig = {
      ...samlIdp.companyMappingConfig,
    };
    samlForm.usingCompanyMapping = true;
  }
  return samlForm;
};

const SamlConfigUpdatePage = ({match}) => {
  const intl = useIntl();
  const history = useHistory();

  const [deleting, setDeleting] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const {companyUuid, providerName} = match.params;
  const {userConfig} = useSelector((state) => state.config);

  const {
    isLoading,
    data: fetchedSamlConfig,
    refetch,
    isFetching,
    error,
  } = useQuery(
    ['fetchedSamlConfig'],
    async () => {
      return await fetchSamlIdp(providerName, companyUuid);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const showLoader = isLoading || isFetching;
  const showDelete = userConfig.credentialsAuthSupported;

  const samlFormData = fetchedSamlConfig
    ? prepareFormData(fetchedSamlConfig)
    : {};

  const onIdpSettingsSave = async (idpSettings) => {
    try {
      await updateIdpSettings(idpSettings, intl);
      toast({
        title: intl.formatMessage({id: 'SAML_IDP_UPDATE_SUCCESS_TITLE'}),
        description: intl.formatMessage({
          id: 'SAML_IDP_UPDATE_SUCCESS_DESCRIPTION',
        }),
        type: 'success',
        time: 4000,
      });
      return await refetch();
    } catch (err) {
      return handleIdpSaverError(err, intl);
    }
  };

  const onDelete = async () => {
    try {
      setDeleting(true);
      await deleteSamlIdp(fetchedSamlConfig.providerName);
      history.push('/settings/security');
      toast({
        title: intl.formatMessage({id: 'SAML_IDP_DELETE_SUCCESS_TITLE'}),
        description: intl.formatMessage({
          id: 'SAML_IDP_DELETE_SUCCESS_DESCRIPTION',
        }),
        type: 'success',
        time: 4000,
      });
    } catch (error) {
      handleGeneralIdpRequestError(error, intl);
    } finally {
      setDeleting(false);
      setConfirmModalVisible(false);
    }
  };

  if (error) {
    if (error.response?.status === 404) {
      return <PageNotFound history={history} />;
    } else if (error?.response?.status === 403) {
      toast({
        title: intl.formatMessage({id: '403_UNAUTHORIZED'}),
        description: intl.formatMessage({
          id: '403_UNAUTHORIZED_DESCRIPTION',
        }),
        type: 'error',
        time: 4000,
      });
      return history.goBack();
    } else {
      return <InternalError history={history} />;
    }
  }

  return (
    <SettingsPageContainer>
      <SettingsHeader page={SettingsPages.SECURITY}></SettingsHeader>

      <ConfirmationModal
        open={confirmModalVisible}
        headerMessageId="SAML_IDP_DELETE_MODAL_TITLE"
        onOpen={() => setConfirmModalVisible(true)}
        onClose={() => setConfirmModalVisible(false)}
        onConfirm={onDelete}>
        <FormattedMessage id="SAML_IDP_DELETE_MODAL_CONTENT">
          {(chunks) => htmlParse(chunks.join(''))}
        </FormattedMessage>
      </ConfirmationModal>

      <SettingsFormWrapper>
        <CollapseBox
          header={
            <FormattedMessage id="EDIT_SAML_CONFIG_TITLE"></FormattedMessage>
          }
          data-spec="saml-config-col-box">
          {showLoader && <Loader active></Loader>}
          {!showLoader && (
            <SamlConfigForm
              intl={intl}
              initialValues={samlFormData}
              onSubmit={onIdpSettingsSave}>
              {showDelete && (
                <>
                  <Header>
                    <FormattedMessage id="SAML_CONFIG_DELETE_TITLE"></FormattedMessage>
                  </Header>
                  <InfoMessageWrapper>
                    <FormattedMessage id="SAML_CONFIG_DELETE_DESCRIPTION"></FormattedMessage>
                  </InfoMessageWrapper>
                  <DeleteButton
                    loading={deleting}
                    disabled={deleting}
                    data-spec={'delete-saml-btn'}
                    type="button"
                    onClick={() => setConfirmModalVisible(true)}>
                    <FormattedMessage id="SAML_CONFIG_DELETE_BUTTON" />
                    <Icon name="trash" />
                  </DeleteButton>
                </>
              )}
            </SamlConfigForm>
          )}
        </CollapseBox>
      </SettingsFormWrapper>
    </SettingsPageContainer>
  );
};

SamlConfigUpdatePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      providerName: PropTypes.string,
      companyUuid: PropTypes.string,
    }),
  }),
};

export default SamlConfigUpdatePage;

import PropTypes from 'prop-types';
import React from 'react';
import {Message} from 'semantic-ui-react';
import styled from 'styled-components';

export const ErrorContainer = styled.div`
  margin-bottom: 25px;
`;

const LogEvent = ({eventId}) => (
  <div style={{marginTop: 0}}>
    <br /> Request ID: {eventId}
  </div>
);

LogEvent.propTypes = {
  eventId: PropTypes.string.isRequired,
};

const ErrorComponent = ({errorHeader, errorContent, eventId}) => {
  return (
    <ErrorContainer>
      <Message error data-spec="invalid-config">
        <Message.Header style={{marginBottom: 15}}>
          {errorHeader}
        </Message.Header>
        <Message.Content>
          {errorContent}
          {eventId ? <LogEvent eventId={eventId} /> : null}
        </Message.Content>
      </Message>
    </ErrorContainer>
  );
};

export default ErrorComponent;

import React from 'react';
import {connect} from 'react-redux';
import {Grid, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {parse, stringify} from 'qs';

const rowHeight = '19vw';

const itemBase = {
  margin: '5px',
  boxShadow: '3px 3px 8px 0px rgba(0,0,0,0.3)',
  position: 'relative',
  borderRadius: '4px',
};

const styles = {
  grid: {
    marginTop: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    maxHeight: '100vh',
    justifyContent: 'center',
  },
  item: {
    narrow: {
      ...itemBase,
      width: '26.5vw',
      height: rowHeight,
    },
    wide: {
      ...itemBase,
      width: '41.5vw',
      height: rowHeight,
    },
  },
  overlay: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
    padding: '10px',
    fontStyle: 'italic',
  },
  title: {
    fontSize: '1.5em',
    color: 'white',
    borderRadius: '5px',
    padding: '10px 20px',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'inline-block',
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
};

const replaceTranslationStringContext = (translationId, isOfferingCompany) => {
  if (translationId.indexOf('{CONTEXT}') === -1) {
    return translationId;
  } else {
    return translationId.replace(
      '{CONTEXT}',
      isOfferingCompany ? 'PROVIDING' : 'USING'
    );
  }
};

const mapStateToProps = ({config}) => ({
  config,
});

const LinkButton = injectIntl(
  ({interaction, servers, param = '__lang', intl, isOffering, ...rest}) => {
    let url =
      interaction.app && servers[interaction.app]
        ? `${servers[interaction.app]}${interaction.url}`
        : interaction.url;

    // add __lang param if its an application link
    if (interaction.app) {
      const URI = url.split('?') || '';
      const paramObj = parse(URI[1] || null) || {};
      const cleanUrl = URI[0] || url;

      // Dont overwrite the param if given
      if (Object.keys(paramObj).indexOf(param) < 0) {
        paramObj[param] = intl.locale;
      }

      url = `${cleanUrl}?${stringify(paramObj)}`;
    }

    return (
      <Button
        as="a"
        href={url}
        fluid
        primary
        {...rest}
        disabled={interaction.show.disabled}>
        <FormattedMessage
          id={replaceTranslationStringContext(interaction.title, isOffering)}
        />
      </Button>
    );
  }
);

const showCompare = (item, offeringCompany) => {
  return (
    (offeringCompany && item.show.onlyOfferingCompany && item.show.visible) ||
    (!offeringCompany && item.show.onlyUsingCompany && item.show.visible) ||
    (!item.show.onlyOfferingCompany &&
      !item.show.onlyUsingCompany &&
      item.show.visible)
  );
};

const Interactions = ({data, columns, servers, offeringCompany}) => {
  const filtered = data.filter((item) => showCompare(item, offeringCompany));

  return (
    <div style={{marginTop: '10px'}}>
      <Grid>
        <Grid.Row columns={columns || filtered.length}>
          {filtered.map((interaction, idx) => {
            // FIXME proper styling for marginTop property
            return (
              <Grid.Column
                key={`${interaction.title}-${idx}`}
                style={{marginTop: '10px'}}>
                <LinkButton
                  interaction={interaction}
                  servers={servers}
                  isOffering={offeringCompany}
                />
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </div>
  );
};

Interactions.propTypes = {
  columns: PropTypes.number,
  data: PropTypes.any,
  offeringCompany: PropTypes.bool,
  servers: PropTypes.object,
};

const Tile = ({data, servers, offeringCompany}) => {
  const {
    name,
    backgroundUrl,
    interactions,
    format,
    description,
    interactionColumns,
  } = data;

  const style = {
    ...styles.item[format],
    backgroundImage: `url(${backgroundUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  };

  return (
    <div style={style}>
      <h1 style={styles.title}>
        <FormattedMessage
          id={replaceTranslationStringContext(name, offeringCompany)}
        />
      </h1>
      <div style={styles.overlay}>
        <FormattedMessage
          id={replaceTranslationStringContext(description, offeringCompany)}
        />
        <Interactions
          data={interactions}
          columns={interactionColumns}
          servers={servers}
          offeringCompany={offeringCompany}
        />
      </div>
    </div>
  );
};

Tile.propTypes = {
  data: PropTypes.any,
  offeringCompany: PropTypes.bool,
  servers: PropTypes.object,
};

const Template = ({config}) => {
  const {userConfig, servers} = config;
  const sorted = userConfig.tiles
    .sort((a, b) => a.sort - b.sort)
    .filter((item) => showCompare(item, userConfig.offeringCompany));
  return (
    <div style={styles.grid}>
      {sorted.map((tile) => {
        return (
          <Tile
            key={`${tile.name}-${tile.sort}`}
            data={tile}
            servers={servers}
            offeringCompany={userConfig.offeringCompany}
          />
        );
      })}
    </div>
  );
};

Template.propTypes = {
  config: PropTypes.any,
};

export default connect(mapStateToProps)(Template);

import axios from 'axios';
import {OpenFeature} from '@openfeature/js-sdk';

const createValue = (result) => {
  return {value: result.data};
};

export class AccountsFeatureProvider {
  metadata = {
    name: 'Monitor Feature Provider',
  };

  resolveBooleanEvaluation(flagKey, defaultValue) {
    try {
      return axios
        .get(`/api/features/boolean/${flagKey}/${defaultValue}`)
        .then(createValue);
    } catch (error) {
      return {
        reason: error.message,
        value: false,
      };
    }
  }

  resolveStringEvaluation(flagKey, defaultValue) {
    try {
      return axios
        .get(`/api/features/string/${flagKey}/${defaultValue}`)
        .then(createValue);
    } catch (error) {
      return {
        reason: error.message,
        value: false,
      };
    }
  }

  resolveNumberEvaluation() {
    return Promise.reject('Unsupported');
  }

  resolveObjectEvaluation(flagKey) {
    try {
      return axios.get(`/api/features/object/${flagKey}`).then(createValue);
    } catch (error) {
      return {
        reason: error.message,
        value: false,
      };
    }
  }
}

OpenFeature.setProvider(new AccountsFeatureProvider());
const client = OpenFeature.getClient('accounts');

export default client;

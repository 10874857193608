import styled from 'styled-components';

import {ecosioColors} from '@ecosio/customer-layout';

export const ColumnHeader = styled.span`
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${(props) => props.theme.secondaryColor};
`;

export const SubscriptionRow = styled.div`
  background: white;
  align-items: center !important;
  border-left: 2px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  max-width: 80%;
`;

export const SubscriptionList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5rem;
  max-width: 80%;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: ${(props) => props.width};
  padding: 0.5rem;
  background: ${(props) => props.background || 'inherit'};
`;

export const FlexRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const SubmitText = styled.span`
  color: ${ecosioColors.successGreen};
  align-self: center;
  margin-left: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: auto;
  max-width: 1400px;
`;

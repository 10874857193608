import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Login, toast} from '@ecosio/components';
import axios from 'axios';
import {logout} from '@ecosio/auth';
import {Modal} from 'semantic-ui-react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import logger from '../../logger';

const debug = logger('SessionExpiredDialog');

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {error: false};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    if (this.state.error) {
      return null;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Component which connects to the accounts server server-sent-event source a
 * and listens to SESSION_EXPIRED events.
 *
 * Will then show a modal with the password to enter for the user.
 *
 * Ideally, we would have the whole component including the modal and the
 * eventSource handling in the @ecosio/components library, but
 * semantic-ui-react and modals are not compatible in libraries...
 */
const SessionExpiredDialog = ({authServerUrl, username}) => {
  const [modalShown, doShowModal] = useState(false);
  const intl = useIntl();

  const dispatch = useDispatch();

  logger(
    `Rendering dialog with authServer ${authServerUrl} and username ${username}`
  );

  const showError = () => {
    toast({
      title: intl.formatMessage({
        id: 'GENERAL_ERROR',
      }),
      type: 'error',
      time: 100 * 1000,
    });
  };

  const handleMessage = (event) => {
    debug(`Message received, ${JSON.stringify(event)}`);
    const data = JSON.parse(event?.data);
    // TODO: also filter by username to avoid having all users see this
    // screen in case of a backend bug...
    if (data?.payload?.type === 'SESSION_EXPIRED') {
      // triggered by some that requires the user to do a full logout
      // including a redirect to the login page
      // examples: admin2 company change, force logout in admin2,
      // being removed from partner relation etc.
      // basically anything where the user needs a full config-reload from
      // the backend
      if (data?.payload?.forceExpiryOfAllSessions) {
        window.sessionStorage.setItem('sessionExpired', 'true');
        dispatch(logout(axios, authServerUrl));
      } else {
        // a organic session-expiry - all the config is still the same
        // but the user just was idle long enough to hit the session-expiry
        doShowModal(true);
      }
    }
  };

  Login.useEventSource(
    `${authServerUrl}/api/session/notifications`,
    handleMessage
  );

  return (
    <Modal open={modalShown}>
      <Modal.Content>
        <Login.SessionExpiredForm
          axios={axios}
          username={username}
          authServerUrl={authServerUrl}
          onError={showError}
          onLoginSuccess={() => doShowModal(false)}
          onSessionExpired={() => doShowModal(true)}
        />
      </Modal.Content>
    </Modal>
  );
};

SessionExpiredDialog.propTypes = {
  authServerUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

const FailsafeExpiredSessionDialog = ({authServerUrl, username}) => (
  <ErrorBoundary>
    <SessionExpiredDialog authServerUrl={authServerUrl} username={username} />
  </ErrorBoundary>
);

FailsafeExpiredSessionDialog.propTypes = {
  authServerUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default FailsafeExpiredSessionDialog;

import htmlParse from 'html-react-parser';

import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Icon, Modal} from 'semantic-ui-react';
import styled from 'styled-components';

const ModalHeaderStyle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  color: ${(props) => props.theme.secondaryColor};
  margin-left: 1.5rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

export const ModalHeader = ({children, onClose}) => (
  <>
    <Icon
      name="close"
      link
      onClick={onClose}
      style={{position: 'absolute', right: '10px', top: '10px'}}
    />
    <ModalHeaderStyle>{children}</ModalHeaderStyle>
  </>
);

const ModalContent = styled.div`
  margin-left: 1.5rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

ModalHeader.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

const ConfirmationModal = ({
  open,
  headerMessageId,
  children,
  onOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal open={open} onOpen={onOpen} onClose={onClose}>
      <Modal.Content>
        <ModalHeader onClose={onClose}>
          <FormattedMessage id={headerMessageId}>
            {(chunks) => htmlParse(chunks.join(''))}
          </FormattedMessage>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
      </Modal.Content>

      <Modal.Actions>
        <Button
          positive
          onClick={() => {
            onConfirm();
            onClose();
          }}>
          <FormattedMessage id="GENERAL_YES" />
        </Button>

        <Button negative onClick={onClose}>
          <FormattedMessage id="GENERAL_NO" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  headerMessageId: PropTypes.string.isRequired,
  children: PropTypes.node,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;

import {CheckBoxField, InputField, intlShape, toast} from '@ecosio/components';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {FormattedMessage} from 'react-intl';
import {Button, Form, Grid, Icon, List, Modal, Popup} from 'semantic-ui-react';
import styled from 'styled-components';

import {ModalHeader} from '../../../Modals/ConfirmationModal/ConfirmationModal';

import {requiredEmail} from '../../../../helpers/validators';
import SubCompanyDropdown from '../../../SubCompanies/SubCompanyDropdown';
import {ButtonIcon} from '../Helpers';
import './InviteModal.css';
import {useUserConfig} from '../../../../hooks/useUserConfig';
import {MainCategory, trackEvent} from '../../../../analytics';

const IconWrapper = styled.div`
  flex: 1;
  text-align: right;
  align-self: center;
`;

const helpTextProps = {
  size: 'large',
  position: 'label',
};

const ValidationErrors = ({error}) => {
  return (
    <List>
      {Object.values(error).map((e, index) => {
        // semgrep bug here, we don't do xpath evaluation...
        const data = JSON.parse(e); // nosemgrep nodejs_scan.javascript-xml-rule-node_xpath_injection
        return (
          <List.Item key={index}>
            <FormattedMessage id={data.translationKey} values={data.values} />
          </List.Item>
        );
      })}
    </List>
  );
};

const InviteModal = ({intl, onInviteSent, ...modalProps}) => {
  const userConfig = useUserConfig();
  const configSubCompanies = userConfig?.subCompanies || [];
  const hasSubCompanies = configSubCompanies.length > 0;

  const trackAnalytics = (event) => {
    const segmentation = {
      mainCategory: MainCategory.ButtonClick,
      companyUuid: userConfig?.company?.uuid,
      companyName: userConfig?.company?.name,
    };
    trackEvent(event, segmentation);
  };

  const onSubmit = (values) => {
    const invites = values.invites.map((invite) => ({
      ...invite,
      email: invite.email.trim(),
      companyUuid: invite.companyUuid,
    }));
    const body = {invites};

    axios
      .post('/api/company/users/invite', body)
      .then(() => {
        if (modalProps.onClose) {
          modalProps.onClose();
        }
        if (onInviteSent) {
          onInviteSent();
        }
        trackAnalytics('Send invites');
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          toast({
            title: intl.formatMessage({id: 'GENERAL_ERROR'}),
            description: <ValidationErrors error={error.response.data} />,
            type: 'error',
          });
        } else {
          console.error(error);
        }
      });
  };

  const colWidth = hasSubCompanies ? 8 : 16;
  return (
    <Modal {...modalProps}>
      <Modal.Content>
        <ModalHeader onClose={modalProps.onClose}>
          <FormattedMessage id="GENERAL_INVITE_NEW_USER" />
        </ModalHeader>

        <FinalForm
          onSubmit={onSubmit}
          mutators={arrayMutators}
          initialValues={{
            invites: [
              {
                email: '',
                companyUuid: userConfig?.company?.uuid,
                companyAdmin: false,
                messagingOperations: false,
              },
            ],
          }}
          render={({handleSubmit}) => (
            <Form onSubmit={handleSubmit} style={{marginTop: '0'}}>
              <Grid className="invite-form">
                <FieldArray name="invites">
                  {({fields}) => (
                    <React.Fragment>
                      {fields.map((name, idx) => (
                        <React.Fragment key={idx}>
                          <Grid.Row>
                            <Grid.Column
                              width={colWidth}
                              data-spec={`email-col-${colWidth}`}>
                              <Form.Group style={{alignItems: 'end'}}>
                                <Field
                                  name={`${name}.email`}
                                  translateError
                                  component={InputField}
                                  validate={requiredEmail}
                                  label={intl.formatMessage({
                                    id: 'GENERAL_EMAIL',
                                  })}
                                  placeholder="user@example.com"
                                  width={16}
                                  required
                                  data-spec={`invite-${name}-email`}
                                />
                              </Form.Group>
                            </Grid.Column>
                            {hasSubCompanies && (
                              <Grid.Column width={8} data-spec="sub-col">
                                <Field name={`${name}.companyUuid`}>
                                  {(props) => {
                                    return (
                                      <div className="field">
                                        <label style={{marginBottom: 8}}>
                                          Company
                                        </label>
                                        <div className="ui input">
                                          <SubCompanyDropdown
                                            style={{width: '100%'}}
                                            selection={props.input.value}
                                            onChange={props.input.onChange}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Field>
                              </Grid.Column>
                            )}
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <Form.Group widths="4" className="checkbox-group">
                                <Field
                                  name={`${name}.companyAdmin`}
                                  component={CheckBoxField}
                                  label={intl.formatMessage({
                                    id: 'USER_PERMISSION_COMPANY_ADMIN',
                                  })}
                                  width={6}
                                  data-spec={`invite-${name}-company-admin`}
                                  helpText={intl.formatMessage({
                                    id:
                                      'USER_PERMISSION_COMPANY_ADMIN_DESCRIPTION',
                                  })}
                                  helpTextProps={helpTextProps}
                                />
                                <Field
                                  className="message-ops"
                                  name={`${name}.messagingOperations`}
                                  component={CheckBoxField}
                                  label={intl.formatMessage({
                                    id:
                                      'USER_PERMISSION_MONITOR_MESSAGING_OPERATIONS',
                                  })}
                                  width={6}
                                  data-spec={`invite-${name}-messaging-operations`}
                                  helpText={intl.formatMessage({
                                    id:
                                      'USER_PERMISSION_MONITOR_MESSAGING_OPERATIONS_DESCRIPTION',
                                  })}
                                  helpTextProps={helpTextProps}
                                />
                                <Field
                                  // className="message-ops"
                                  name={`${name}.partnerMatrix`}
                                  component={CheckBoxField}
                                  label={intl.formatMessage({
                                    id: 'USER_PERMISSION_PARTNER_MATRIX',
                                  })}
                                  width={6}
                                  data-spec={`invite-${name}-partner-matrix`}
                                  helpText={intl.formatMessage({
                                    id:
                                      'USER_PERMISSION_PARTNER_MATRIX_DESCRIPTION',
                                  })}
                                  helpTextProps={helpTextProps}
                                />
                                <Field
                                  // className="message-ops"
                                  name={`${name}.supportTickets`}
                                  component={CheckBoxField}
                                  label={intl.formatMessage({
                                    id: 'USER_PERMISSION_ELENA_SUPPORT_TICKETS',
                                  })}
                                  width={6}
                                  data-spec={`invite-${name}-partner-matrix`}
                                  helpText={intl.formatMessage({
                                    id:
                                      'USER_PERMISSION_ELENA_SUPPORT_TICKETS_DESCRIPTION',
                                  })}
                                  helpTextProps={helpTextProps}
                                />
                                {idx > 0 && (
                                  <IconWrapper>
                                    <Popup
                                      trigger={
                                        <Icon
                                          name="close"
                                          link
                                          onClick={() => {
                                            fields.remove(idx);
                                            trackAnalytics(
                                              'Remove user invite'
                                            );
                                          }}
                                        />
                                      }
                                      content={
                                        <FormattedMessage id="GENERAL_REMOVE" />
                                      }
                                    />
                                  </IconWrapper>
                                )}
                              </Form.Group>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Button
                            data-spec="invite-add-field"
                            floated="left"
                            positive
                            type="button"
                            onClick={() => {
                              fields.push({
                                email: '',
                                companyAdmin: false,
                                companyUuid: userConfig?.company?.uuid,
                              });
                              trackAnalytics('Add user invite');
                            }}>
                            <FormattedMessage id="ADD_INVITE" />
                            <ButtonIcon name="plus" />
                          </Button>
                          <Button
                            type="submit"
                            data-spec="invite-submit"
                            floated="right">
                            <FormattedMessage id="INVITE_MODAL_SUBMIT" />
                            <ButtonIcon name="angle right" />
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  )}
                </FieldArray>
              </Grid>
            </Form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

InviteModal.propTypes = {
  onError: PropTypes.func,
  intl: intlShape.isRequired,
  onInviteSent: PropTypes.func,
};

export default InviteModal;

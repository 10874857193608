import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useEffect} from 'react';

/**
 *
 * @param query {Record<string, string | string[]>?}
 * @returns {UseQueryResult<any, unknown>}
 */
export const useAssigneeGroups = (query) => {
  return useQuery({
    queryKey: ['assignee-group-details', query],
    queryFn: async () => {
      const response = await axios.get(`/api/elena/external-assignee-groups`, {
        params: query,
      });
      return response.data;
    },
  });
};

export const useAssignableUsers = () => {
  const userConfig = useSelector((state) => state.config?.userConfig);

  return useQuery({
    queryKey: ['assignee-group-users'],
    queryFn: async () => {
      const params = {
        externalCompanyUuid: userConfig.company.uuid,
        size: 500,
      };
      const response = await axios.get(
        '/api/elena/external-assignee-groups/allowed-member-users',
        {params}
      );

      if (!response.data.last) {
        console.warn(
          'Received more than 500 users for company',
          userConfig.company.uuid
        );
      }

      return response.data;
    },
  });
};

export const useAssigneeGroup = (uuid) => {
  return useQuery({
    queryKey: ['assignee-group', uuid],
    queryFn: async () => {
      const response = await axios.get(
        `/api/elena/external-assignee-groups/${uuid}`
      );
      const group = response.data;
      return {
        ...group,
        members: group.members.map((m) => m.uuid),
      };
    },
  });
};

export const useRedirectIfNoPermission = (requiredAuthority) => {
  const history = useHistory();
  const userConfig = useSelector((state) => state.config?.userConfig);

  useEffect(() => {
    if (!userConfig) {
      return;
    }

    const {authorities, ticketingEnabled} = userConfig;

    if (!ticketingEnabled || !authorities.includes(requiredAuthority)) {
      history.push('/404');
    }
  }, [userConfig, history, requiredAuthority]);
};

export const AssigneeGroupAuthority = {
  READ: 'ELENA_ASSIGNEE_GROUP_VIEW',
  EDIT: 'ELENA_ASSIGNEE_GROUP_EDIT',
};

import {CollapseBox} from '@ecosio/components';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import Label from '../../Layout/Label';
import {SettingsFormWrapper} from '../SettingsStyles';

const SummaryWrapper = styled.div`
  position: absolute;
  right: 80px;
  display: flex;
  justify-content: space-between;
`;

export const SettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  background-color: white;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
`;

const StatusSummary = ({messageId, checked = false}) => (
  <SummaryWrapper data-spec="status-summary">
    {checked && (
      <Icon
        name="check circle"
        color="green"
        style={{marginRight: '10px'}}></Icon>
    )}
    <Label messageId={messageId}></Label>
  </SummaryWrapper>
);

StatusSummary.propTypes = {
  messageId: PropTypes.string,
  checked: PropTypes.bool,
};

const StatusSummaryCollapseBox = ({
  headerId,
  dataSpec,
  checked,
  summaryMsgId,
  children,
  style,
}) => {
  const header = (
    <div
      style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
      <FormattedMessage id={headerId} />
      <StatusSummary messageId={summaryMsgId} checked={checked}></StatusSummary>
    </div>
  );
  return (
    <SettingsFormWrapper style={style}>
      <CollapseBox
        key={headerId}
        data-spec={dataSpec}
        relativePosition
        collapsible
        header={header}
        headerSeparated={true}>
        {children}
      </CollapseBox>
    </SettingsFormWrapper>
  );
};

StatusSummaryCollapseBox.propTypes = {
  headerId: PropTypes.string.isRequired,
  dataSpec: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  summaryMsgId: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default StatusSummaryCollapseBox;

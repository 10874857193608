import {InputField, intlShape} from '@ecosio/components';
import React from 'react';
import {Field} from 'react-final-form';
import {FormattedMessage} from 'react-intl';
import {Form, Header} from 'semantic-ui-react';
import {required} from '../../../../helpers/validators';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';

const SamlAttributeMapping = ({intl}) => {
  return (
    <>
      <Header>
        <FormattedMessage id="ATTRIBUTE_MAPPING_TITLE"></FormattedMessage>
      </Header>
      <InfoMessageWrapper>
        <FormattedMessage
          id={'ATTRIBUTE_MAPPING_DESCRIPTION'}></FormattedMessage>
      </InfoMessageWrapper>
      <Form.Group>
        <Field
          component={InputField}
          name="email"
          label={intl.formatMessage({id: 'GENERAL_EMAIL'})}
          helpText={<FormattedMessage id="GENERAL_EMAIL_HELP" />}
          required
          placeholder={intl.formatMessage({id: 'SAML_ATTRIBUTE'})}
          width={16}
          translateError
          validate={required}
          data-spec="email-attribute-field"></Field>
      </Form.Group>
      <Form.Group>
        <Field
          component={InputField}
          name="firstName"
          label={intl.formatMessage({id: 'GENERAL_FIRST_NAME'})}
          helpText={<FormattedMessage id="GENERAL_FIRST_NAME_HELP" />}
          required
          placeholder={intl.formatMessage({id: 'SAML_ATTRIBUTE'})}
          width={16}
          translateError
          validate={required}
          data-spec="first-name-attribute-field"></Field>
      </Form.Group>
      <Form.Group>
        <Field
          component={InputField}
          name="lastName"
          label={intl.formatMessage({id: 'GENERAL_LAST_NAME'})}
          helpText={<FormattedMessage id="GENERAL_LAST_NAME_HELP" />}
          required
          placeholder={intl.formatMessage({id: 'SAML_ATTRIBUTE'})}
          width={16}
          translateError
          validate={required}
          data-spec="last-name-attribute-field"></Field>
      </Form.Group>
    </>
  );
};

SamlAttributeMapping.propTypes = {
  intl: intlShape.isRequired,
};

export default SamlAttributeMapping;

import React from 'react';
import {Popup, Icon} from 'semantic-ui-react';
import {FormatDateTime} from '@ecosio/components';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const RelativeDate = ({dateString}) => {
  return (
    <Popup
      content={<FormatDateTime dateString={dateString} stripTimezone={false} />}
      trigger={
        <FormatDateTime
          dateString={dateString}
          relative
          stripTimezone={false}
        />
      }
    />
  );
};

RelativeDate.propTypes = {
  dateString: PropTypes.string.isRequired,
};

export const isDisabled = (user) => !user.activated;

export const AvatarStyles = styled.span`
  display: inline-flex;
  border-radius: 500rem;
  background-color: ${(props) => (props.disabled ? 'transparent' : '#D9E5FF')};
  color: ${(props) => (props.disabled ? '#808080' : '#002268')};
  font-weight: 700;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  ${(props) => props.disabled && 'border: 1px solid #E5E5E5;'}
`;

/**
 *
 * @param firstName {string | undefined}
 * @param lastName {string | undefined}
 * @param email {string}
 * @returns {JSX.Element}
 * @constructor
 */
export const Avatar = ({firstName, lastName, email, disabled}) => {
  let initials;
  if (firstName && lastName) {
    initials = (firstName[0] + lastName[0]).toUpperCase();
  } else {
    initials = email.substring(0, 2).toUpperCase();
  }

  return (
    <Popup
      content={
        <div>
          {firstName && lastName && (
            <div style={{fontSize: '12px', textAlign: 'center'}}>
              {firstName} {lastName}
            </div>
          )}
          <span style={{fontSize: '10px'}}>{email}</span>
        </div>
      }
      trigger={<AvatarStyles disabled={disabled}>{initials}</AvatarStyles>}
    />
  );
};

Avatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const AvatarList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

export const ButtonIcon = styled(Icon)`
  margin-left: 1rem !important;
`;

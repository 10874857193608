import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Modal} from 'semantic-ui-react';
import {ecosioColors} from '@ecosio/customer-layout';
import styled from 'styled-components';

const InnerContent = styled.div`
  background-color: ${ecosioColors.greyShades[5]};
  padding: ${(props) => (props.withPadding ? '0 25px' : '0')};
`;

const PaddedContent = styled.div`
  padding: 25px;
`;

/**
 * TODO: copied over from the monitor, should somehow move to a library
 */
const MonitorModalContent = ({
  children,
  header,
  subHeader,
  onClose,
  alerts,
  innerPadding = false,
}) => {
  return (
    <Modal.Content
      style={{
        background: 'white',
        padding: 0,
      }}>
      <Icon
        name="close"
        link
        onClick={onClose}
        style={{position: 'absolute', right: '10px', top: '10px'}}
      />
      <PaddedContent>
        <h1 style={{color: ecosioColors.secondaryBlue}}>{header}</h1>
        {alerts}
        {subHeader && <p>{subHeader}</p>}
      </PaddedContent>
      <InnerContent withPadding={innerPadding}>{children}</InnerContent>
    </Modal.Content>
  );
};

MonitorModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  subHeader: PropTypes.node,
  alerts: PropTypes.node,
  innerPadding: PropTypes.bool,
};

export default MonitorModalContent;

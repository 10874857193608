import {useQuery} from '@tanstack/react-query';
import featureFlagClient from '../featureflags/FeatureFlagProvider';

const toValue = (result) => {
  return result.value;
};

const handleError = (error) => {
  console.error(error);
  return false;
};

export const useBooleanFeatureFlag = (key, defaultValue) => {
  return useQuery(
    {
      queryKey: ['booleanFeatureFlag', key],
      queryFn: () => {
        return featureFlagClient
          .getBooleanValue(key, defaultValue)
          .then(toValue)
          .catch(handleError);
      },
    },
    {refetchOnWindowFocus: false}
  );
};

export const useStringFlag = (key, defaultValue, enabled = true) => {
  return useQuery(
    {
      queryKey: ['stringFeatureFlag', key],
      enabled,
      queryFn: () => {
        return featureFlagClient
          .getStringValue(key, defaultValue)
          .then(toValue)
          .catch(handleError);
      },
    },
    {refetchOnWindowFocus: false}
  );
};

export const useObjectFeatureFlag = (key, enabled = true) => {
  return useQuery(
    {
      queryKey: ['objectFeatureFlag', key],
      enabled,
      queryFn: () => {
        return featureFlagClient
          .getObjectValue(key)
          .then(toValue)
          .catch(handleError);
      },
    },
    {refetchOnWindowFocus: false}
  );
};

import styled from 'styled-components';

export const PaginationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const InnerContainer = styled.div`
  margin: 0 48px 0 48px;
`;

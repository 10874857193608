import {CheckBoxField, CollapseBox, intlShape, toast} from '@ecosio/components';
import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {
  FormattedMessage,
  FormattedMessage as Msg,
  injectIntl,
} from 'react-intl';
import {Header, Icon} from 'semantic-ui-react';

import htmlParse from 'html-react-parser';
import {MainCategory, trackEvent} from '../../../analytics';
import {useUserConfig} from '../../../hooks/useUserConfig';
import {
  Column,
  ColumnHeader,
  FlexRow,
  HeaderRow,
  SubmitText,
  SubscriptionList,
  SubscriptionRow,
} from './styledComponents';

const ExternalNotifications = ({intl}) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [savedRecently, setSavedRecently] = useState(false);
  const [toggleInProgress, setToggleInProgress] = useState(false);
  const userConfig = useUserConfig();
  const email = userConfig?.email;

  const handleGeneralError = useCallback(
    (err) => {
      const response = err.response;
      if (response?.status >= 500) {
        console.error(err);
      }
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: err.message,
        type: 'error',
      });
    },
    [intl]
  );

  useEffect(() => {
    const fetchIsSubscribed = async () => {
      try {
        const res = await axios.get('/api/user/statuspal/subscription');
        setIsSubscribed(res.status === 200);
      } catch (err) {
        if (err.response.status === 404) {
          setIsSubscribed(false);
        } else {
          handleGeneralError(err);
        }
      }
    };

    fetchIsSubscribed();
  }, [intl, handleGeneralError]);

  const subscriptionToggleHandler = async () => {
    if (toggleInProgress) {
      return;
    }
    setToggleInProgress(true);
    const call = isSubscribed ? axios.delete : axios.post;
    try {
      const res = await call('/api/user/statuspal/subscription');
      trackEvent('Status page notification toggle', {
        mainCategory: MainCategory.ElementClick,
        item: !isSubscribed,
        companyUuid: userConfig?.company?.uuid,
        companyName: userConfig?.company?.name,
      });
      if (res.status < 400) {
        setIsSubscribed(!isSubscribed);
        setSavedRecently(true);

        setTimeout(() => setSavedRecently(false), 30000);
      } else {
        console.warn(res);
      }
    } catch (err) {
      handleGeneralError(err);
    } finally {
      setToggleInProgress(false);
    }
  };

  const collabseBoxChild = (
    <React.Fragment>
      <FlexRow>
        <div style={{maxWidth: '90%'}}>
          <FormattedMessage id="STATUSPAL_NOTIFICATIONS_DESCRIPTION">
            {(chunks) => htmlParse(chunks.join(''))}
          </FormattedMessage>
        </div>
      </FlexRow>
      <SubscriptionList>
        <HeaderRow>
          <Column>
            <ColumnHeader>
              <Msg id="NOTIFICATION_TYPE" />
            </ColumnHeader>
          </Column>

          <Column>
            <ColumnHeader>
              <Msg id="NOTIFICATION_TYPE_EMAIL" />
            </ColumnHeader>
          </Column>
        </HeaderRow>
        <FinalForm
          onSubmit={subscriptionToggleHandler}
          initialValues={isSubscribed}
          render={() => {
            return (
              <FlexRow>
                <SubscriptionRow>
                  <Column width="50%">
                    <Msg id="STATUSPAL_UPDATES_LABEL" />
                  </Column>
                  <Column width="50%">
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Field
                        data-spec="statuspal-toggler"
                        name="statuspalNotificationsToggler"
                        component={CheckBoxField}
                        toggle
                        inverted={isSubscribed}
                        style={{marginRight: '1rem'}}
                        onChange={subscriptionToggleHandler}
                        checked={isSubscribed}
                      />
                      {email}
                    </div>
                  </Column>
                </SubscriptionRow>
                {savedRecently && (
                  <SubmitText data-spec="setting-saved-msg">
                    <Icon name="check circle outline" />
                    <Msg id="SUBSCRIPTION_SETTINGS_SAVED" />
                  </SubmitText>
                )}
              </FlexRow>
            );
          }}></FinalForm>
      </SubscriptionList>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Header>
        <FormattedMessage id="EXTERNAL_NOTIFICATIONS" />
      </Header>

      <CollapseBox
        onToggleCollapse={(state) => {
          trackEvent('Expand external notifications', {
            mainCategory: MainCategory.ButtonClick,
            item: state,
            companyUuid: userConfig?.company?.uuid,
            companyName: userConfig?.company?.name,
          });
        }}
        style={{margin: '0px 0px 16px 0'}}
        key={'STATUSPAL_NOTIFICATIONS'}
        data-spec="statuspal-col-box"
        relativePosition
        collapsible
        initiallyCollapsed
        header={<Msg id="STATUSPAL_NOTIFICATIONS" />}>
        {collabseBoxChild}
      </CollapseBox>
    </React.Fragment>
  );
};

ExternalNotifications.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ExternalNotifications);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {Button, Header, Icon} from 'semantic-ui-react';
import {isInternetExplorer} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import {STATIC_ASSETS_BASE} from '../../../constants';
import {openChatWindow} from './SupportLink';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-image: -webkit-linear-gradient(160deg, #0043cb 50%, #0054ff 50%);
`;

const MessageContainer = styled.div`
  width: 33%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  word-break: break-all;
`;

const LogoContainer = styled.div`
  width: 25%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  width: 33%;
  align-items: center;
  justify-content: center;
`;

const ButtonIconWrapper = styled.span`
  height: 12px;
  width: 16px;
  margin-left: 10px;
  margin-right: 16px;
  color: ${ecosioColors.greyShades[7]};
  font-family: 'Font Awesome 5 Free';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
`;

const HeaderSubHeader = styled.div`
  height: 44px;
  width: 308px;
  color: #5c5d5d;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`;

/*
const ChatInfoContainer = styled.div`
  color: #fff;
  text-transform: initial;
  padding-left: 6px;
  padding-right: 6px;
`;
 */

export const openEmailClient = () => {
  return window.open(`mailto:support@ecosio.com`);
};

const PrimaryButton = styled(Button)`
  background-color: ${ecosioColors.errorRed};
  box-shadow: '0 6px 19px 0 rgba(0,0,0,0.24)';
`;

export const isSupportChatEnabled = (userConfig) => {
  if (isInternetExplorer()) {
    return false;
  }

  if (!userConfig?.offeringCompany) {
    return false;
  }

  return userConfig?.supportChatEnabled === true;
};

const OurSupport = ({userConfig, authServer}) => {
  const chatEnabled = isSupportChatEnabled(userConfig);
  return (
    <Container>
      <MessageContainer>
        <Header as="h2" style={{display: 'inline', whiteSpace: 'nowrap'}}>
          <Header.Content style={{color: '#FFFFFF'}}>
            <FormattedMessage id="OUR_SUPPORT_CONTACT_HEADER" />
            <Header.Subheader>
              <HeaderSubHeader style={{color: '#FFFFFF', opacity: '0.8'}}>
                <a style={{color: '#fff'}} href="mailto:support@ecosio.com">
                  support@ecosio.com
                </a>
              </HeaderSubHeader>
            </Header.Subheader>
          </Header.Content>
        </Header>
      </MessageContainer>

      <LogoContainer>
        <img
          src={`${STATIC_ASSETS_BASE}/assets/ecosio-klammer.svg`}
          height="96.92px"
          width="102.92px"
          alt="ecosio-klammer"
        />
      </LogoContainer>

      <ButtonContainer>
        <div>
          <PrimaryButton
            data-spec="our-support-page-contact-button"
            primary
            style={{marginBottom: 10, width: '100%'}}
            onClick={
              chatEnabled
                ? openChatWindow.bind(this, authServer, userConfig?.email)
                : openEmailClient
            }
            icon>
            <FormattedMessage
              id={chatEnabled ? 'CHAT_WITH_SUPPORT' : 'CONTACT_SUPPORT_MONITOR'}
            />
            <ButtonIconWrapper>
              <Icon name="angle right" />
            </ButtonIconWrapper>
          </PrimaryButton>
        </div>
      </ButtonContainer>
    </Container>
  );
};

OurSupport.propTypes = {
  userConfig: PropTypes.shape({email: PropTypes.string.isRequired}).isRequired,
};

export default connect(({config, ...rest}) => ({...config, ...rest}))(
  OurSupport
);

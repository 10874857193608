import React, {useState} from 'react';
import {Input, Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

const PatternInput = styled.input`
  border-radius: 2em !important;
  border: 1px solid white !important;
  font-size: 12px;
  height: 32px;
`;

const AddCustomPattern = ({onHandleAddToken, intl}) => {
  const [inputValue, setInputValue] = useState('');

  const onHandleChange = (e) => {
    let value = e.currentTarget.value;
    value = value.replace(/[#%&{}\\<>*?\s$!'":@+`|=]/g, '');
    setInputValue(value);
  };

  const onHandleInputValue = () => {
    if (inputValue) {
      onHandleAddToken(inputValue, true);
      setInputValue('');
    }
  };

  return (
    <>
      <Input icon>
        <PatternInput
          onChange={onHandleChange}
          value={inputValue}
          placeholder={intl.formatMessage({
            id: 'FILENAME_PATTERN_INPUT_PLACEHOLDER',
          })}
        />
        <Icon name="add" link onClick={onHandleInputValue} />
      </Input>
    </>
  );
};

AddCustomPattern.propTypes = {
  onHandleAddToken: PropTypes.func.isRequired,
  //  intl: intlShape.isRequired
};

export default injectIntl(AddCustomPattern);

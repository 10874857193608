import {DropdownField} from '@ecosio/components';
import htmlParse from 'html-react-parser';
import React from 'react';
import {Field} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Form, Header, Loader} from 'semantic-ui-react';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import useSamlAllowedCompanies from '../hooks/useSamlAllowedCompanies';

const SamlRootCompanySelector = () => {
  const intl = useIntl();
  const userConfig = useSelector((state) => state.config?.userConfig);

  const {isLoading, data: samlCompanies} = useSamlAllowedCompanies(
    userConfig.company.uuid
  );

  if (isLoading) {
    return <Loader></Loader>;
  }

  const options = samlCompanies?.map((company) => ({
    value: company.uuid,
    text: company.name,
  }));

  return (
    <>
      <Header>
        <FormattedMessage id="GENERAL_SELECT_COMPANY"></FormattedMessage>
      </Header>
      <InfoMessageWrapper>
        <FormattedMessage id="ROOT_COMPANY_SELECTOR_INFO"></FormattedMessage>
        <Form.Group style={{marginTop: '1rem'}}>
          <Field
            label={intl.formatMessage({id: 'GENERAL_COMPANY'})}
            name={`rootCompanyUuid`}
            component={DropdownField}
            options={options}
            helpText={<FormattedMessage id="SAML_ROOT_COMPANY_HELP" />}
            width={16}
          />
        </Form.Group>
        <FormattedMessage id="MISSING_SAML_COMPANY">
          {(chunks) => htmlParse(chunks.join(''))}
        </FormattedMessage>
      </InfoMessageWrapper>
    </>
  );
};

export default SamlRootCompanySelector;

import {
  CircularLabel,
  CollapseBox,
  InputField,
  toast,
} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Button, Form, Icon, List, Message, Modal} from 'semantic-ui-react';
import styled from 'styled-components';

import htmlParse from 'html-react-parser';
import {Helmet} from 'react-helmet';
import {locationShape} from '../../../../shapes';
import {stringifyQuery} from '../../../helpers/utils';
import {
  processValidationResult,
  requiredEmail,
  translateValidationResult,
} from '../../../helpers/validators';
import Label from '../../Layout/Label';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import {FormFieldsWrapper} from '../FormFieldsWrapper';
import {InfoMessageWrapper} from '../InfoMessageWrapper';
import SettingsHeader from '../SettingsHeader';
import SettingsPages from '../settingsPages';
import {
  MessageInputWrapper,
  WarningIcon,
  SettingsFormWrapper,
} from '../SettingsStyles';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import {MainCategory, trackEvent} from '../../../analytics';
import {useUserConfig} from '../../../hooks/useUserConfig';

const EmailBox = styled.div`
  background: white;
  padding: 17px 24px;
  color: ${ecosioColors.secondaryBlue};
  margin-bottom: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
  height: 70px;
`;

const EmailText = styled.span`
  line-height: 16px;
  letter-spacing: 0;
  margin-left: 1rem;
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(55%);
`;

const VerifiedIcon = styled(Icon)`
  color: ${(props) =>
    props.verified ? props.theme.primaryColor : ecosioColors.greyShades[2]};
`;

const AddIcon = styled(Icon)`
  line-height: 8px;
  padding-left: 0.5rem;
`;

const Grid = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    flex-flow: column;
  }
`;

const NestedGrid = styled.div`
  display: flex;
  flex-flow: column;
  flex: 7;
`;

const AddEmailContainer = styled.div`
  margin-top: 3rem;
`;

const DeleteEmailModal = ({
  emailToDelete,
  onClose,
  intl,
  isOfferingCompany,
  onEmailDeleted,
}) => {
  const [loading, setLoading] = useState(true);
  const [affectedSubscriptions, setAffectedSubscriptions] = useState(null);
  const config = useUserConfig();

  useEffect(() => {
    if (emailToDelete?.email) {
      const url = `/api/subscription/email?${stringifyQuery({
        email: emailToDelete.email,
      })}`;
      axios
        .get(url)
        .then((res) => {
          setAffectedSubscriptions(res.data);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          toast({
            title: intl.formatMessage({id: 'GENERAL_ERROR'}),
            description: e.message,
            type: 'error',
          });
          setLoading(false);
        });
    }
  }, [emailToDelete?.email]);

  const onDeleteEmail = async () => {
    try {
      await axios.delete(
        `/api/user/emails?${stringifyQuery({email: emailToDelete.email})}`
      );
      trackEvent('Delete secondary email', {
        mainCategory: MainCategory.ButtonClick,
        companyUuid: config?.company?.uuid,
        companyName: config?.company?.name,
      });

      onClose();
      onEmailDeleted(emailToDelete);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal open={Boolean(emailToDelete)} onClose={onClose}>
      <Modal.Header>
        <FormattedMessage id="GENERAL_PLEASE_CONFIRM" />
      </Modal.Header>

      <Modal.Content>
        {!loading && (
          <>
            <FormattedMessage
              id={
                affectedSubscriptions?.length > 0
                  ? 'DELETE_EMAIL_MODAL_BODY_LONG'
                  : 'DELETE_EMAIL_MODAL_BODY_SHORT'
              }
              values={{email: emailToDelete?.email}}
            />
            <List bulleted>
              {affectedSubscriptions?.map((subscription) => (
                <List.Item key={subscription.uuid}>
                  <FormattedMessage
                    id={`${isOfferingCompany ? 'PROVIDING' : 'USING'}_EVENT_${
                      subscription.event
                    }`}
                  />
                </List.Item>
              ))}
            </List>
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={onDeleteEmail} positive>
          <FormattedMessage id="GENERAL_YES" />
        </Button>
        <Button onClick={onClose}>
          <FormattedMessage id="GENERAL_NO" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DeleteEmailModal.propTypes = {
  onClose: PropTypes.fn,
  //  intl: intlShape.isRequired,
  emailToDelete: PropTypes.object,
  isOfferingCompany: PropTypes.bool.isRequired,
};

const EmailsPage = ({location}) => {
  const [data, setData] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState({});
  // holds the state for the delete modal, if it is truthy, the modal is visible
  const [emailToDelete, setEmailToDelete] = useState(null);
  const intl = useIntl();
  const config = useSelector((state) => state.config.userConfig);

  if (location.state?.emailVerified) {
    toast({
      title: intl.formatMessage({id: 'EMAIL_CONFIRM_SUCCESS'}),
      description: intl.formatMessage({
        id: 'EMAIL_CONFIRM_SUCCESS_DESCRIPTION',
      }),
      time: 30 * 1000,
      type: 'success',
    });
  }

  const fetchEmails = () => {
    return axios
      .get('/api/user/emails')
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  const onAddNewEmail = async (values, form) => {
    try {
      const response = await axios.put(
        `/api/user/emails?${stringifyQuery({email: values.email})}`
      );

      trackEvent('Add secondary email', {
        mainCategory: MainCategory.ButtonClick,
        companyUuid: config?.company?.uuid,
        companyName: config?.company?.name,
      });

      const email = response.data;
      setData(({secondaryEmailAddresses: emails}) => ({
        secondaryEmailAddresses: [...emails, email],
      }));
      form.resetFieldState('email');
      form.reset();
    } catch (err) {
      const status = err.response?.status;
      if (status === 409) {
        // email already exists in some form
        return {email: intl.formatMessage({id: 'EMAIL_CONFLICT_ERROR'})};
      } else if (status === 400) {
        // validation error, i.e. invalid email address that wasn't caught by
        // frontend validation
        const validationResults = processValidationResult(
          err.response.data,
          intl
        );

        return translateValidationResult(validationResults, intl);
      } else {
        // probably an internal server error, show a toast
        console.error(err);
        toast({
          title: intl.formatMessage({id: 'GENERAL_ERROR'}),
          description: err.message,
          type: 'error',
        });
      }
    }
  };

  const onResendVerificationMail = async ({email}) => {
    try {
      await axios.post(
        `/api/user/emails/resend-verification?${stringifyQuery({email})}`
      );
      // Disable the button, to avoid users spam-clicking.
      setDisabledButtons((oldState) => ({
        ...oldState,
        [email]: true,
      }));

      // Re-enable the button after 60 seconds
      window.setTimeout(() => {
        setDisabledButtons((oldState) => ({
          ...oldState,
          [email]: false,
        }));
      }, 60 * 1000);

      toast({
        title: intl.formatMessage({id: 'CONFIRMATION_LINK_SENT'}),
        description: intl.formatMessage({
          id: 'CONFIRMATION_LINK_SENT_DESCRIPTION',
        }),
        time: 30 * 1000,
        type: 'success',
      });
    } catch (err) {
      toast({
        title: intl.formatMessage({id: 'GENERAL_ERROR'}),
        description: err.message,
        type: 'error',
      });
      console.error(err);
    }
  };

  const userId = config.email;
  const verifiedEmails = [];
  const unverifiedEmails = [];
  data?.secondaryEmailAddresses.forEach((e) => {
    const isVerified = Boolean(e.verifiedAt);
    const mail = (
      <EmailBox key={e.email}>
        <VerifiedIcon verified={isVerified} name="circle" />
        <EmailText style={{textOverflow: 'ellipsis'}}>{e.email}</EmailText>
        {userId === e.email && (
          <CircularLabel styles={{display: 'inline', marginLeft: '1rem'}}>
            <FormattedMessage id="USER_ID" />
          </CircularLabel>
        )}

        <div style={{marginLeft: 'auto', whiteSpace: 'nowrap'}}>
          {!isVerified && (
            <Button
              disabled={disabledButtons[e.email]}
              compact
              onClick={() => onResendVerificationMail(e)}>
              <FormattedMessage id="RESEND_VERIFICATION_MAIL" />
            </Button>
          )}

          {userId !== e.email && (
            <Button compact icon onClick={() => setEmailToDelete(e)}>
              <Icon name="delete" />
            </Button>
          )}
        </div>
      </EmailBox>
    );

    if (isVerified) {
      verifiedEmails.push(mail);
    } else {
      unverifiedEmails.push(mail);
    }
  });

  return (
    <SettingsPageContainer>
      <Helmet>
        <title>{createPageTitle(intl, 'EMAIL_PAGE_TITLE')}</title>
      </Helmet>
      <SettingsHeader page={SettingsPages.EMAILS} />

      <DeleteEmailModal
        emailToDelete={emailToDelete}
        onClose={() => setEmailToDelete(null)}
        intl={intl}
        isOfferingCompany={config.offeringCompany}
        onEmailDeleted={() => fetchEmails()}
      />

      <SettingsFormWrapper>
        <Message style={{background: '#f2f2f2'}}>
          <MessageInputWrapper>
            <WarningIcon name="warning sign" size="big" />
            <FormattedMessage id="EMAIL_SETTINGS_INFO_BOX_MESSAGE">
              {(chunks) => htmlParse(chunks.join(''))}
            </FormattedMessage>
          </MessageInputWrapper>
        </Message>
        <Grid>
          <NestedGrid>
            <CollapseBox
              header={<FormattedMessage id="EMAIL_SETTINGS_HEADER" />}>
              <InfoMessageWrapper>
                <FormattedMessage id="EMAIL_SETTINGS_INFO_BOX_MESSAGE">
                  {(chunks) => htmlParse(chunks.join(''))}
                </FormattedMessage>
              </InfoMessageWrapper>
              <FormFieldsWrapper>
                {verifiedEmails.length > 0 && (
                  <Label messageId={'VERIFIED'}></Label>
                )}
                {verifiedEmails}
                {unverifiedEmails.length > 0 && (
                  <Label messageId={'UNVERIFIED'}></Label>
                )}
                {unverifiedEmails}
                <AddEmailContainer>
                  <FinalForm
                    onSubmit={(values, form) => {
                      return onAddNewEmail(values, form);
                    }}
                    render={({handleSubmit, invalid}) => (
                      <Form onSubmit={handleSubmit}>
                        <FormFieldsWrapper>
                          <Label
                            messageId={
                              'EMAIL_SETTINGS_INFO_ADD_SECONDARY_EMAIL'
                            }></Label>
                          <Field
                            data-spec="emails-add-email-field"
                            name="email"
                            component={InputField}
                            placeholder={intl.formatMessage({
                              id: 'EMAIL_INPUT_PLACEHOLDER',
                            })}
                            validate={requiredEmail}
                            action={{
                              content: (
                                <>
                                  <FormattedMessage id="ADD_NEW_EMAIL" />{' '}
                                  <AddIcon name="plus" />
                                </>
                              ),
                              type: 'submit',
                              disabled: invalid,
                            }}
                          />
                        </FormFieldsWrapper>
                      </Form>
                    )}
                  />
                </AddEmailContainer>
              </FormFieldsWrapper>
            </CollapseBox>
          </NestedGrid>
        </Grid>
      </SettingsFormWrapper>
    </SettingsPageContainer>
  );
};

EmailsPage.propTypes = {
  location: locationShape.isRequired,
};

export default EmailsPage;

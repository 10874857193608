import React from 'react';
import PropTypes from 'prop-types';
import {Authority} from '@ecosio/auth';
import {FormattedMessage} from 'react-intl';
import {Menu, Header, Grid, Placeholder} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import RequiresAuthority from '../RequiresAuthority';
import useCompany from '../../hooks/useCompany';
import {MainCategory, trackEvent} from '../../analytics';
import SettingsPages from './settingsPages';
import {AssigneeGroupAuthority} from './AssigneeGroups/hooks';

const WrappedMenuItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const COMPANY_TABS = [
  {path: SettingsPages.COMPANY, requiredAuthority: null},
  {path: SettingsPages.TEAM, requiredAuthority: Authority.COMPANY_USER_ADD},
  {
    path: SettingsPages.ASSIGNEE_GROUPS,
    requiredAuthority: AssigneeGroupAuthority.READ,
    requiresConfigValue: (config) => config.ticketingEnabled === true,
  },
  {path: SettingsPages.PARTNER_RELATIONS, requiredAuthority: null},
  {path: SettingsPages.SUPPORT, requiredAuthority: null},
  {path: SettingsPages.E_INVOICING, requiredAuthority: null},
  {path: SettingsPages.ADVANCED, requiredAuthority: null},
  {
    path: SettingsPages.SECURITY,
    requiredAuthority: Authority.COMPANY_USER_ADD,
  },
];

const USER_TABS = [
  {path: SettingsPages.ACCOUNT, requiredAuthority: null},
  {path: SettingsPages.PASSWORD, requiredAuthority: null},
  {path: SettingsPages.SECONDARY_EMAILS, requiredAuthority: null},
  {path: SettingsPages.NOTIFICATIONS, requiredAuthority: null},
  {path: SettingsPages.ACCOUNT_SECURITY, requiredAuthority: null},
];

const filteredCompanyTabs = (showConnectionsTab) =>
  COMPANY_TABS.filter(({path}) =>
    showConnectionsTab ? true : path !== SettingsPages.E_INVOICING
  );

const filteredUserTabs = (credentialsAuthSupported) =>
  USER_TABS.filter(({path}) =>
    credentialsAuthSupported
      ? true
      : path !== SettingsPages.ACCOUNT_SECURITY &&
        path !== SettingsPages.PASSWORD
  );

const SettingsHeader = ({page, sideContent}) => {
  const userConfig = useSelector((state) => state.config.userConfig);
  const isCompanyPage = COMPANY_TABS.some((tab) => tab.path === page);
  const credentialsAuthSupported = useSelector(
    (state) => state.config?.userConfig?.credentialsAuthSupported
  );
  const {company, loading} = useCompany(isCompanyPage);
  const showConnectionsTab =
    !loading && Boolean(company?.invoiceSettings?.username);

  const tabList = isCompanyPage
    ? filteredCompanyTabs(showConnectionsTab)
    : filteredUserTabs(credentialsAuthSupported);

  const trackTabClick = (item) => {
    trackEvent(
      isCompanyPage
        ? 'Company settings option tabs'
        : 'User settings option tabs',
      {
        mainCategory: MainCategory.ElementClick,
        item,
        companyUuid: userConfig.companyUuid,
        companyName: userConfig.companyName,
      }
    );
  };

  return (
    <Grid columns={2} doubling style={{marginBottom: '32px'}}>
      <Grid.Row>
        <Grid.Column width={3} textAlign="left">
          <Header as="h1" style={{display: 'inline'}}>
            <FormattedMessage
              id={isCompanyPage ? 'COMPANY_SETTINGS' : 'ACCOUNT_SETTINGS'}
            />
          </Header>
        </Grid.Column>
        <Grid.Column textAlign="center" width="10">
          {loading ? (
            <Placeholder>
              <Placeholder.Header />
            </Placeholder>
          ) : (
            <Menu data-spec="settings-tabs" pointing secondary compact>
              <WrappedMenuItems>
                {tabList.map(
                  ({path, requiredAuthority, requiresConfigValue}) => {
                    const linkSegment = path.replace(/secondary-/g, '');
                    const link = `/settings/${linkSegment}`;
                    const uppercasePage = path
                      .replace(/[-]/g, '_')
                      .toUpperCase();
                    let hidden = false;
                    if (typeof requiresConfigValue === 'function') {
                      hidden = !requiresConfigValue(userConfig);
                    }

                    if (hidden) {
                      return null;
                    }

                    return (
                      <RequiresAuthority
                        key={path}
                        requires={requiredAuthority}>
                        <Menu.Item
                          data-spec={`SETTINGS_PAGE_${uppercasePage}`}
                          as={NavLink}
                          onClick={() => trackTabClick(path)}
                          to={link}
                          isActive={() => (page ? page === linkSegment : null)}>
                          {path !== 'account' ? (
                            <FormattedMessage id={`GENERAL_${uppercasePage}`} />
                          ) : (
                            <FormattedMessage id="GENERAL_PROFILE_AND_ACCOUNT" />
                          )}
                        </Menu.Item>
                      </RequiresAuthority>
                    );
                  }
                )}
              </WrappedMenuItems>
            </Menu>
          )}
        </Grid.Column>
        {sideContent && (
          <Grid.Column width={3} textAlign="right">
            {sideContent}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

SettingsHeader.propTypes = {
  page: PropTypes.string.isRequired,
  sideContent: PropTypes.node,
};

export default SettingsHeader;

import React from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {Button, Form, Header, Icon, Loader} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {CollapseBox, InputField} from '@ecosio/components';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {SettingsFormWrapper} from '../../SettingsStyles';
import {useAssignableUsers} from '../hooks';
import {useUserConfig} from '../../../../hooks/useUserConfig';
import {MainCategory, trackEvent} from '../../../../analytics';
import CheckboxListField from './CheckboxListField';
import SubCompanyDropdownField from './SubCompanyDropdownField';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  align-self: end;
  margin: 0 -20px -20px 0;
  z-index: 20;
`;

/**
 *
 * @param value {string}
 * @returns {string}
 */
export const transformToSlug = (value) => {
  if (!value) {
    return '';
  }

  return value.toLowerCase().replace(/\s+/g, '-');
};

const validateSlug = (value) => {
  const regex = /[0-9a-z-]+/;
  return regex.test(value) ? undefined : 'ASSIGNEE_GROUP_NAME_INVALID';
};

const AssigneeGroupForm = ({header, mode, initialValues, onSubmit}) => {
  const userConfig = useUserConfig();
  const intl = useIntl();

  const hasSubCompanies = userConfig.subCompanies.length > 1;

  const assignableUsers = useAssignableUsers();
  const nameOptions = assignableUsers.data?.content?.map((user) => ({
    name: user.fullName,
    email: user.email,
    uuid: user.uuid,
    key: user.uuid,
  }));

  const history = useHistory();

  const onCancel = () => {
    history.push('/settings/assignee-groups');
    trackEvent('Cancel new group', {
      mainCategory: MainCategory.ButtonClick,
      companyUuid: userConfig.company.uuid,
      companyName: userConfig.company.name,
    });
  };

  return (
    <SettingsFormWrapper>
      <Header as="h1">
        <FormattedMessage {...header} />
      </Header>
      <CollapseBox>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({handleSubmit}) => (
            <StyledForm data-spec="assignee-group-form" onSubmit={handleSubmit}>
              <div style={{width: '50%', marginBottom: '14px'}}>
                <Field
                  name="name"
                  component={InputField}
                  label={intl.formatMessage({id: 'ASSIGNEE_GROUP_NAME_LABEL'})}
                  placeholder={intl.formatMessage({
                    id: 'ASSIGNEE_GROUP_NAME_PLACEHOLDER',
                  })}
                  format={transformToSlug}
                  validate={validateSlug}
                  required
                />
                <Field
                  name="description"
                  component={InputField}
                  label={intl.formatMessage({
                    id: 'ASSIGNEE_GROUP_DESCRIPTION_LABEL',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'ASSIGNEE_GROUP_DESCRIPTION_PLACEHOLDER',
                  })}
                />
                {hasSubCompanies && (
                  <Field
                    name={
                      mode === 'create'
                        ? 'externalCompanyUuid'
                        : 'externalCompany.uuid'
                    }
                    component={SubCompanyDropdownField}
                    label="ASSIGNEE_GROUP_COMPANY_LABEL"
                  />
                )}
              </div>
              {nameOptions?.length && (
                <Field
                  name={mode === 'create' ? 'unmanagedMemberUuids' : 'members'}
                  component={CheckboxListField}
                  label="ASSIGNEE_GROUP_SELECT_MEMBERS"
                  options={nameOptions}
                  required
                />
              )}
              {assignableUsers.isLoading && <Loader active />}
              <ButtonGroup>
                <Button type="button" secondary onClick={onCancel}>
                  <FormattedMessage id="GENERAL_CANCEL" />
                </Button>
                <Button type="submit" primary>
                  <Icon name="checkmark" />
                  <FormattedMessage id="ASSIGNEE_GROUP_FORM_SAVE" />
                </Button>
              </ButtonGroup>
            </StyledForm>
          )}
        />
      </CollapseBox>
    </SettingsFormWrapper>
  );
};

AssigneeGroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']),
  initialValues: PropTypes.object,
};

export default AssigneeGroupForm;

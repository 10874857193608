import React, {useState} from 'react';
import {toast} from '@ecosio/components';
import {Authority} from '@ecosio/auth';
import {Button, Icon, Popup} from 'semantic-ui-react';
import {FormattedMessage, useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import axios from 'axios';
import RequiresAuthority from '../../RequiresAuthority';
import {deleteUserFromRelation} from './partnerRelationsApi';
import EditRelationPermissionsModal from './EditRelationPermissionsModal';

const ActionCell = ({
  userId,
  relationUuid,
  isPartner,
  onUserDelete,
  userPermissions,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [permissions, setPermissions] = useState(userPermissions);
  const deleteUser = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(intl.formatMessage({id: 'GENERAL_CONFIRM_MESSAGE'}))) {
      try {
        setLoading(true);
        await deleteUserFromRelation(userId, relationUuid);
        toast({
          title: intl.formatMessage({id: 'PARTNER_REMOVED_SUCCESS'}),
          type: 'success',
          time: 4000,
        });
        onUserDelete();
      } catch (error) {
        console.error(error);
        toast({
          title: intl.formatMessage({
            id: 'GENERAL_ERROR',
          }),
          description: error.message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const changeUserPermissions = async (newPermissions) => {
    const url = `/api/partner-relations/${relationUuid}/${userId}/change-authorities`;

    try {
      setPermissionsLoading(true);
      await axios.post(url, {newPermissions});
      setPermissions(newPermissions);
      toast({
        title: intl.formatMessage({
          id: 'PARTNER_RELATION_PERMISSIONS_UPDATED',
        }),
        description: '',
        type: 'success',
        time: 4000,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: error.message,
        type: 'error',
      });
    } finally {
      setModalOpen(false);
      setPermissionsLoading(false);
      onUserDelete();
    }
  };

  const editRelationPermissions = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <RequiresAuthority requires={Authority.COMPANY_USER_UPDATE}>
      {!isPartner && (
        <div style={{display: 'flex'}}>
          <EditRelationPermissionsModal
            open={modalOpen}
            loading={permissionsLoading}
            permissions={permissions}
            userId={userId}
            onClose={closeModal}
            onChange={changeUserPermissions}
            onDelete={deleteUser}
          />
          <Popup
            trigger={
              <Button
                style={{flex: 1}}
                data-spec={`remove-${userId}`}
                loading={loading}
                disabled={loading}
                secondary
                size="mini"
                compact
                icon
                onClick={editRelationPermissions}>
                <Icon name="eye" />
              </Button>
            }
            content={<FormattedMessage id="SETTINGS_USER_EDIT_HEADER" />}
          />
        </div>
      )}
    </RequiresAuthority>
  );
};

ActionCell.propTypes = {
  userId: PropTypes.string.isRequired,
  relationUuid: PropTypes.string.isRequired,
  onUserDelete: PropTypes.func.isRequired,
  isPartner: PropTypes.bool.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ActionCell;

import React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
/**
 * A SubmitButton Component for SettingsPage.
 * The negative margin-right is neccessary to get the correct bottom-right position in the form
 */

export const StyledButton = styled(Button)`
  display: flex !important;
  justify-content: space-between !important;
  width: 160px;
`;

const ButtonWrapper = styled.div`
  display: flex !important;
  flex-direction: row-reverse !important;
  padding-top: 40px;
  margin-bottom: -20px;
  margin-right: -22px;
`;

const SubmitButton = ({textId, disabled, dataSpec, loading}) => {
  return (
    <ButtonWrapper>
      <StyledButton
        loading={loading}
        data-spec={dataSpec}
        type="submit"
        disabled={disabled}>
        <FormattedMessage id={textId} />
        <Icon name="angle right" size="large" />
      </StyledButton>
    </ButtonWrapper>
  );
};

SubmitButton.defaultProps = {
  children: null,
};

SubmitButton.propTypes = {
  dataSpec: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  textId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default SubmitButton;

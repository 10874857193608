import {useSelector} from 'react-redux';

const useRedirectOnboardedUser = () => {
  const samlUserFirstLogin = useSelector(
    (state) => state.config?.userConfig?.samlUserFirstLogin
  );

  if (!samlUserFirstLogin) {
    // alert('redirect');
    // window.location.href = '/account';
  }
};

export default useRedirectOnboardedUser;

import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Grid, Loader} from 'semantic-ui-react';
import {Authority} from '@ecosio/auth';
import {toast} from '@ecosio/components';
import {useSelector} from 'react-redux';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../SettingsHeader';
import SettingsPages from '../settingsPages';
import RequiresAuthority from '../../RequiresAuthority';
import {Img} from '../../Login/ResetPasswordStyles';
import {STATIC_ASSETS_BASE} from '../../../constants';
import {openEmailClient} from '../SupportPage/OurSupport';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import {getPartnerRelations} from './partnerRelationsApi';
import AddPartnerRelationUserModal from './AddPartnerRelationUserModal';
import PartnerRelationUserTable from './PartnerRelationUserTable';

const EmptyState = () => (
  <div>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column verticalAlign="middle">
          <div style={{margin: 'auto', width: '50%'}}>
            <div>
              <h2>
                <FormattedMessage id="NO_PARTNERS_SETUP" />
              </h2>
              <p>
                <FormattedMessage id="NO_PARTNERS_SETUP_DESC" />
              </p>
              <Button onClick={openEmailClient}>
                <FormattedMessage id="CONTACT_SUPPORT_MONITOR" />
              </Button>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Img src={`${STATIC_ASSETS_BASE}/assets/no_partners.svg`} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

const PartnerRelations = () => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [partnerRelations, setPartnerRelations] = useState({});
  const [reloadRelations, setReloadRelations] = useState(true);
  const {userConfig} = useSelector((state) => state.config);
  const isPartner = userConfig?.company?.companyType === 'PARTNER';

  useEffect(() => {
    if (!reloadRelations) {
      return;
    }

    setLoading(true);
    getPartnerRelations()
      .then((result) => {
        setPartnerRelations(result.data);
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: intl.formatMessage({
            id: 'GENERAL_ERROR',
          }),
          description: error.message,
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setReloadRelations(false);
      });
  }, [reloadRelations]);

  const hasPartnerRelations =
    Array.isArray(partnerRelations) && partnerRelations.length > 0;

  const onModalClose = () => {
    setModalOpen(false);
    setReloadRelations(true);
  };

  return (
    <div>
      <SettingsPageContainer>
        <SettingsHeader page={SettingsPages.PARTNER_RELATIONS} />
        <Helmet>
          <title>{createPageTitle(intl, 'GENERAL_PARTNER_RELATIONS')}</title>
        </Helmet>
        <AddPartnerRelationUserModal open={modalOpen} onSave={onModalClose} />
        <Grid>
          {hasPartnerRelations && !isPartner && (
            <Grid.Row>
              <Grid.Column style={{textAlign: 'right'}}>
                <RequiresAuthority requires={Authority.COMPANY_USER_UPDATE}>
                  <Button
                    data-spec="add-partner-user"
                    onClick={() => setModalOpen(true)}>
                    <FormattedMessage id="ADD_PARTNER_USER" />
                  </Button>
                </RequiresAuthority>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>
              {loading ? (
                <Loader active />
              ) : (
                <>
                  {hasPartnerRelations ? (
                    partnerRelations.map((relation) => (
                      <PartnerRelationUserTable
                        isPartner={isPartner}
                        onUserDelete={() => setReloadRelations(true)}
                        key={relation.uuid}
                        relation={relation}
                      />
                    ))
                  ) : (
                    <EmptyState />
                  )}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SettingsPageContainer>
    </div>
  );
};

export default PartnerRelations;

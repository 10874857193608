import {CollapseBox, DataGrid, toast} from '@ecosio/components';
import axios from 'axios';
import qs from 'qs';
import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {Button, Grid, Header, Loader, Message} from 'semantic-ui-react';

import {Authority} from '@ecosio/auth';
import {useQuery} from '@tanstack/react-query';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {historyShape, locationShape} from '../../../../../shapes';
import {createPageTitle} from '../../../../helpers/helmetHelpers';
import {useRequiresAuthority} from '../../../Helper/hooks';
import RequiresAuthority from '../../../RequiresAuthority';
import {SettingsPageContainer} from '../../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../../SettingsHeader';
import {ButtonIcon, isDisabled} from '../Helpers';
import {
  InnerContainer,
  OuterContainer,
  PaginationButtons,
} from '../styledComponents';
import {MainCategory, trackEvent} from '../../../../analytics';
import {useUserConfig} from '../../../../hooks/useUserConfig';
import InviteModal from './InviteModal';
import UserFilter from './UserFilter';
import UserInviteList from './UserInviteList';
import dataGridConfig from './dataGridConfig';

const stringifyQuery = (params) => qs.stringify(params, {addQueryPrefix: true});

const TeamMembersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const TeamPage = ({location}) => {
  const intl = useIntl();
  const params = qs.parse(location.search, {ignoreQueryPrefix: true});

  // Show the modal when the `invite` query parameter is passed in
  const [modalOpen, setModalOpen] = useState(params.invite !== undefined);
  const [inviteSent, setInviteSent] = useState(0);
  const showInviteButton = useSelector(
    (state) => state.config?.userConfig?.credentialsAuthAllowed
  );

  const userConfig = useUserConfig();

  const query = stringifyQuery({...params, sort: 'userId'});

  const {data, isLoading: loadingUsers, error} = useQuery(
    ['users', params],
    async () => {
      const response = await axios.get(`/api/company/users${query}`);

      const currentPage = response.data?.number + 1;
      const nextPageUrl = stringifyQuery({
        ...params,
        page: currentPage + 1,
      });

      const prevPageUrl = stringifyQuery({
        ...params,
        page: currentPage - 1,
      });

      return {users: response.data, nextPageUrl, prevPageUrl};
    }
  );

  const users = data?.users;
  const nextPage = data?.nextPageUrl;
  const prevPage = data?.prevPageUrl;

  useRequiresAuthority(Authority.COMPANY_USER_ADD);

  const trackAnalytics = (event) => {
    trackEvent(event, {
      mainCategory: MainCategory.ButtonClick,
      companyUuid: userConfig?.company?.uuid,
      companyName: userConfig?.company?.name,
    });
  };

  return (
    <SettingsPageContainer fullWidth className="settings-container">
      <InnerContainer>
        <SettingsHeader
          page="team"
          sideContent={
            showInviteButton && (
              <RequiresAuthority requires={Authority.COMPANY_USER_ADD}>
                <Button
                  data-spec="invite-modal-button"
                  style={{maxWidth: '200px'}}
                  onClick={() => {
                    setModalOpen(true);
                    trackAnalytics('Invite new user');
                  }}>
                  <FormattedMessage id="GENERAL_INVITE_NEW_USER" />
                  <ButtonIcon name="add user" />
                </Button>
              </RequiresAuthority>
            )
          }
        />
      </InnerContainer>

      <Helmet>
        <title>{createPageTitle(intl, 'TEAM_PAGE_TITLE')}</title>
      </Helmet>

      {error && (
        <InnerContainer>
          <Message floating error>
            <Message.Header>
              <FormattedMessage id="GENERAL_ERROR" />
            </Message.Header>
            <p>{error.message}</p>
          </Message>
        </InnerContainer>
      )}

      <OuterContainer className="outer-container">
        <CollapseBox style={{marginLeft: '-10px', marginRight: '-10px'}}>
          <div style={{marginLeft: '38px'}}>
            <UserFilter location={location} />
          </div>
        </CollapseBox>

        <InnerContainer>
          <UserInviteList forceRefresh={inviteSent} />
        </InnerContainer>

        <InviteModal
          open={modalOpen}
          onOpen={() => setModalOpen(true)}
          onClose={() => setModalOpen(false)}
          intl={intl}
          size="large"
          onInviteSent={() => {
            setInviteSent((n) => n + 1);
            toast({
              title: intl.formatMessage({id: 'USER_INVITE_CREATE_SUCCESS'}),
              time: 30 * 1000,
              type: 'success',
            });
          }}
        />

        <InnerContainer>
          <TeamMembersHeader>
            <Header as="h3" style={{marginTop: 20}}>
              <FormattedMessage id="GENERAL_TEAM_MEMBERS" />
            </Header>

            <div>
              <RequiresAuthority requires={Authority.COMPANY_USER_ADD}>
                <Button
                  data-spec="download-team-button"
                  style={{maxWidth: '230px'}}
                  as="a"
                  href={`/account/api/company/users/csv${query}`}
                  onClick={() => {
                    trackAnalytics('Download Team Report');
                  }}>
                  <FormattedMessage id="TEAM_CSV_DOWNLOAD" />
                  <ButtonIcon name="download" />
                </Button>
              </RequiresAuthority>
            </div>
          </TeamMembersHeader>
        </InnerContainer>

        {loadingUsers && <Loader active />}
        {!loadingUsers && !error && (
          <InnerContainer>
            <Grid>
              <Grid.Row>
                <Grid.Column data-spec="users-gc">
                  <DataGrid
                    config={dataGridConfig(userConfig)}
                    celled={false}
                    translated
                    emptyState={{
                      icon: 'user',
                      header: 'NO_USERS_FOUND',
                      subHeader: 'NO_USERS_FOUND_SUB',
                    }}
                    data={users?.content || []}
                    rowFormatter={(row) => ({
                      disabled: isDisabled(row),
                    })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </InnerContainer>
        )}

        <InnerContainer>
          <PaginationButtons>
            <Button
              disabled={users?.first}
              as={Link}
              onClick={() => trackAnalytics('Previous page - team page')}
              to={{
                search: prevPage,
              }}>
              <FormattedMessage id="PREVIOUS_PAGE" />
            </Button>

            <Button
              disabled={users?.last}
              as={Link}
              to={{search: nextPage}}
              onClick={() => trackAnalytics('Next page - team page')}>
              <FormattedMessage id="NEXT_PAGE" />
            </Button>
          </PaginationButtons>
        </InnerContainer>
      </OuterContainer>
    </SettingsPageContainer>
  );
};

TeamPage.propTypes = {
  location: locationShape.isRequired,
  history: historyShape.isRequired,
};

export default TeamPage;

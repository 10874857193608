import {intlShape} from '@ecosio/components';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Form, Header} from 'semantic-ui-react';
import styled from 'styled-components';
import {PermissionsCheckboxes} from '../../../Helper/Permissions';
import {FormFieldsWrapper} from '../../FormFieldsWrapper';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import SubmitButton from '../../SubmitButton';
import {
  companyMappingConfigDecorator,
  companyMappingRowsDecorator,
  mappingAttributeDecorator,
} from '../decorators/formDecorators';
import useHasSubcompanies from '../hooks/useHasSubcompanies';
import IdpSettings from '../model/IdpSettings';
import SamlAppConfig from './SamlAppConfig';
import SamlAttributeMapping from './SamlAttributeMapping';
import SamlCompanyMappingConfig from './SamlCompanyMappingConfig';
import SamlExternalIdpSettings from './SamlExternalIdpSettings';
import SamlRootCompanySelector from './SamlRootCompanySelector';
import SamlSignInMethods from './SamlSignInMethods';
import SamlUsingSubcompaniesToggler from './SamlUsingSubcompaniesToggler';

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const prepareSamlPayload = (formData) => {
  const idpSettings = new IdpSettings({
    ...formData,
    companyMappingConfig: formData.usingCompanyMapping
      ? formData.companyMappingConfig
      : null,
    companyUuid: formData.rootCompanyUuid,
  });

  if (formData.version !== undefined) {
    idpSettings.version = formData.version;
  }

  return idpSettings;
};

const SamlConfigForm = ({intl, onSubmit, initialValues, children}) => {
  const {userConfig} = useSelector((state) => state.config);

  const parentCompany = userConfig?.company?.uuid;

  const hasSubcompanies = useHasSubcompanies(parentCompany);

  if (!initialValues.rootCompanyUuid) {
    initialValues = {...initialValues, rootCompanyUuid: parentCompany};
  }

  const submitForm = (formData) => {
    const payload = prepareSamlPayload(formData);
    return onSubmit(payload);
  };

  return (
    <FinalForm
      onSubmit={submitForm}
      initialValues={initialValues}
      mutators={arrayMutators}
      decorators={[
        mappingAttributeDecorator,
        companyMappingRowsDecorator,
        companyMappingConfigDecorator,
      ]}
      render={({handleSubmit, values, submitting}) => {
        return (
          <Form
            onSubmit={handleSubmit}
            style={{marginTop: '5rem'}}
            data-spec="saml-config-form">
            <FormFieldsWrapper>
              {parentCompany && (
                <SamlAppConfig
                  providerName={values.providerName}
                  companyUuid={parentCompany}></SamlAppConfig>
              )}

              {hasSubcompanies && (
                <SamlRootCompanySelector></SamlRootCompanySelector>
              )}
              <Field name="version" component="input" type="hidden" />
              <SamlExternalIdpSettings
                intl={intl}
                isEditMode={
                  !!initialValues.providerName
                }></SamlExternalIdpSettings>

              <SamlAttributeMapping intl={intl}></SamlAttributeMapping>

              {hasSubcompanies && (
                <SamlUsingSubcompaniesToggler
                  usingCompanyMapping={
                    !!values.usingCompanyMapping
                  }></SamlUsingSubcompaniesToggler>
              )}
              {hasSubcompanies &&
                values.usingCompanyMapping &&
                values.companyMappingConfig && (
                  <SamlCompanyMappingConfig
                    rootCompanyUuid={values.rootCompanyUuid}
                    mappingMode={
                      values.companyMappingConfig.mappingMode
                    }></SamlCompanyMappingConfig>
                )}

              <Header>
                <FormattedMessage id="USER_PERMISSIONS"></FormattedMessage>
              </Header>
              <InfoMessageWrapper>
                <FormattedMessage id="SAML_USER_PERMISSIONS_DESCRIPTION"></FormattedMessage>
              </InfoMessageWrapper>
              <Form.Group>
                <PermissionsCheckboxes
                  isAdminDisabled={false}
                  disabled={false}></PermissionsCheckboxes>
              </Form.Group>

              <SamlSignInMethods
                credentialsAuthAllowed={
                  !!values.credentialsAuthAllowed
                }></SamlSignInMethods>

              {children}
            </FormFieldsWrapper>
            <Footer>
              {/* TODO: disable while submitting */}
              <SubmitButton
                loading={submitting}
                disabled={submitting}
                dataSpec="save-saml-cofig-button"
                textId="SAVE_CHANGES"></SubmitButton>
            </Footer>
          </Form>
        );
      }}></FinalForm>
  );
};

SamlConfigForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default SamlConfigForm;

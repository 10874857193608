import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import {CheckBoxField} from '@ecosio/components';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {Loader} from 'semantic-ui-react';
import {getPermissionTranslationKey} from '../../helpers/translations';
import {useBooleanFeatureFlag} from '../../hooks/featureFlags';

export const Permissions = {
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  MONITOR_MESSAGING_OPERATIONS: 'MONITOR_MESSAGING_OPERATIONS',
  PARTNER_MATRIX: 'PARTNER_MATRIX',
  ELENA_SUPPORT_TICKETS: 'ELENA_SUPPORT_TICKETS',
};

const PermissionInfoText = styled.p`
  margin-left: calc(17px + 0.85em);
`;

const checkboxLabelStyle = {
  fontSize: '12px',
};

const CheckboxFieldWrapper = styled.div`
  .inline.field {
    margin-bottom: 0.25rem;
  }
`;

const checkboxStyle = {
  paddingTop: 5,
};

/**
 * Retrieves formValues rendering PermissionsCheckboxes and transforms the permission
 * object values to backend permission collection.
 */
export const getBackendPermissions = (formValues) => {
  const newPermissions = [];

  Object.keys(Permissions).forEach((permission) => {
    if (formValues[permission]) {
      newPermissions.push(permission);
    }
  });

  return newPermissions;
};

/**
 * Get an object for a form visualizing the available permissions, use in conjunction
 * with getBackendPermissions above.
 */
export const getPermissionsFormObject = (permissions) => {
  return Object.keys(Permissions).reduce((prev, current) => {
    prev[current] = permissions.indexOf(current) > -1;
    return prev;
  }, {});
};

export const PermissionsList = ({permissions, style}) => {
  return (
    <div style={style}>
      {!permissions.length && (
        <span>
          <FormattedMessage id="NONE" />
        </span>
      )}
      {permissions.map((perm, i) => [
        i > 0 && ', ',
        <span key={perm}>
          <FormattedMessage id={getPermissionTranslationKey(perm)} />
        </span>,
      ])}
    </div>
  );
};

PermissionsList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
};

/**
 * Renders a checkbox for each AuthorityGroup / Permission.
 */
export const PermissionsCheckboxes = ({isAdminDisabled, disabled}) => {
  const intl = useIntl();

  // TODO: add support for loading a sub-tree of the configuration so we only
  // query once
  const {
    isLoading: supportTicketsLoading,
    data: supportTicketsEnabled,
  } = useBooleanFeatureFlag('monitor.support_tickets_details', false);

  const {
    isLoading: partnerMatrixLoading,
    data: partnerMatrixEnabled,
  } = useBooleanFeatureFlag('monitor.partner_matrix', false);

  if (supportTicketsLoading || partnerMatrixLoading) {
    return <Loader active />;
  }

  let numPermissions = 2;

  if (supportTicketsEnabled) {
    numPermissions += 1;
  }

  if (partnerMatrixEnabled) {
    numPermissions += 1;
  }

  const width = 100 / numPermissions;

  return (
    <div style={{display: 'flex', gap: '1rem'}} data-spec="permission-fields">
      <CheckboxFieldWrapper style={{width: `${width}%`}}>
        <Field
          style={checkboxStyle}
          name={Permissions.COMPANY_ADMIN}
          component={CheckBoxField}
          label={intl.formatMessage({
            id: 'USER_PERMISSION_COMPANY_ADMIN',
          })}
          inline
          disabled={isAdminDisabled || disabled}
          labelStyle={checkboxLabelStyle}
          data-spec="company-admin-permission-checkbox"
        />
        <PermissionInfoText>
          <FormattedMessage id="USER_PERMISSION_COMPANY_ADMIN_DESCRIPTION" />
        </PermissionInfoText>
      </CheckboxFieldWrapper>
      <CheckboxFieldWrapper style={{width: `${width}%`}}>
        <Field
          style={checkboxStyle}
          name={Permissions.MONITOR_MESSAGING_OPERATIONS}
          component={CheckBoxField}
          label={intl.formatMessage({
            id: 'USER_PERMISSION_MONITOR_MESSAGING_OPERATIONS',
          })}
          inline
          disabled={disabled}
          labelStyle={checkboxLabelStyle}
          data-spec="messaging-operations-permission-checkbox"
        />
        <PermissionInfoText>
          <FormattedMessage id="USER_PERMISSION_MONITOR_MESSAGING_OPERATIONS_DESCRIPTION" />
        </PermissionInfoText>
      </CheckboxFieldWrapper>
      {partnerMatrixEnabled && (
        <CheckboxFieldWrapper style={{width: `${width}%`}}>
          <Field
            style={checkboxStyle}
            name={Permissions.PARTNER_MATRIX}
            component={CheckBoxField}
            label={intl.formatMessage({
              id: 'USER_PERMISSION_PARTNER_MATRIX',
            })}
            inline
            disabled={disabled}
            labelStyle={checkboxLabelStyle}
            data-spec="partner-matrix-permission-checkbox"
          />
          <PermissionInfoText>
            <FormattedMessage id="USER_PERMISSION_PARTNER_MATRIX_DESCRIPTION" />
          </PermissionInfoText>
        </CheckboxFieldWrapper>
      )}

      {supportTicketsEnabled && (
        <CheckboxFieldWrapper style={{width: `${width}%`}}>
          <Field
            style={checkboxStyle}
            name={Permissions.ELENA_SUPPORT_TICKETS}
            component={CheckBoxField}
            label={intl.formatMessage({
              id: 'USER_PERMISSION_ELENA_SUPPORT_TICKETS',
            })}
            inline
            disabled={disabled}
            labelStyle={checkboxLabelStyle}
            data-spec="support-tickets-permission-checkbox"
          />
          <PermissionInfoText>
            <FormattedMessage id="USER_PERMISSION_ELENA_SUPPORT_TICKETS_DESCRIPTION" />
          </PermissionInfoText>
        </CheckboxFieldWrapper>
      )}
    </div>
  );
};

PermissionsCheckboxes.defaultProps = {
  disabled: false,
  isAdminDisabled: false,
  permissions: [],
  onClick: () => {},
  style: {},
};

PermissionsCheckboxes.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  style: PropTypes.object,
  isAdminDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
};
